import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../shared/contexts/NotificationContext";
import BinIcon from "../../../shared/icons/BinIcon";
import { logError } from "../../../shared/logging";
import adminApi from "../../api/adminApi";

interface Props {
  objectId: string;
  objectType: string;
  open: boolean;
  onClose: () => void;
  onDeleted: () => void;
}

const deleteObject = withErrorHandling(adminApi.deleteObject);

const DeleteObjectDialog = ({ objectId, objectType, open, onClose, onDeleted }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);

  const handleDeleteClick = async () => {
    setLoading(true);
    const [, error] = await deleteObject(objectType, objectId);
    setLoading(false);

    if (error) {
      logError(error, "[DeleteObjectDialog]");
      sendNotificationError("Failed to delete the object");
      return;
    }

    sendNotification("Object deleted successfully");
    onDeleted();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete object?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to delete this object. This action is permanent and can&apos;t be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" loading={loading} onClick={handleDeleteClick}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteObjectDialog;
