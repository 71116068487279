import { Dialog, ModalOwnProps, SxProps, Theme } from "@mui/material";
import { PropsWithChildren } from "react";

interface Props extends Pick<ModalOwnProps, "hideBackdrop"> {
  open: boolean;
  sx?: SxProps<Theme>;
}
export default function PromptDialog({ open, sx, children, hideBackdrop }: PropsWithChildren<Props>) {
  return (
    <Dialog
      open={open}
      hideBackdrop={hideBackdrop}
      slotProps={{
        paper: {
          sx: {
            width: 440,
            minHeight: 250,
            borderRadius: 1,
            p: 0.5,
            overflow: "hidden",
            ...sx,
          },
        },
      }}
    >
      {children}
    </Dialog>
  );
}
