import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { reportingPeriodCaptionsMap } from "../../../../../shared/utilities/enumCaptions";
import { autoFormatCamelCase } from "../../../../../shared/utilities/stringHelper";
import { combineValidators, requiredValidator, uniqueValidator } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import {
  allDataCollectionRequestAuditoryValues,
  DataCollectionRequestAuditory,
  DataCollectionRequestTemplateVersion,
  DataSubmissionConfiguration,
} from "../../../../api/types/dataCollectionTypes";
import InlinePropertyBox from "../../../common/InlinePropertyBox";
import { useDataRequestSetupPageContext } from "./DataRequestSetupPageContext";

interface Props {
  onClose: () => void;
  onCreated: (template: DataCollectionRequestTemplateVersion) => void;
  existingTemplateNames: string[];
}

const createDataRequestTemplateVersion = withErrorHandling(adminApi.createDataRequestTemplateVersion);

const validateForm = (name: string, auditory: string, categoryId: string, existingTemplateNames: string[]) => {
  const validateName = combineValidators(
    requiredValidator,
    uniqueValidator("A template with this name already exists", existingTemplateNames)
  );

  const nameResult = validateName(name);
  const auditoryResult = requiredValidator(auditory);
  const categoryIdResult = requiredValidator(categoryId);

  return {
    isValid: nameResult.isValid && auditoryResult.isValid && categoryIdResult.isValid,
    nameResult,
    auditoryResult,
    categoryIdResult,
  };
};

const getConfigurationSettingsProperties = (
  configuration: DataSubmissionConfiguration | undefined
): Array<[string, string]> => [
  ["Scenario", configuration?.isScenarioConfigurable ? "N/A (configurable)" : (configuration?.scenario ?? "")],
  ["Period", configuration?.reportingPeriod ? reportingPeriodCaptionsMap[configuration.reportingPeriod] : ""],
];

const CreateDataRequestTemplateDialog = ({ onClose, onCreated, existingTemplateNames }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { configurations, categories, objectDefinition } = useDataRequestSetupPageContext();

  const [name, setName] = useState("");
  const [configurationId, setConfigurationId] = useState("");
  const [auditory, setAuditory] = useState<DataCollectionRequestAuditory | "">("");
  const [categoryId, setCategoryId] = useState("");
  const [loading, setLoading] = useState(false);
  const [touchedFields, setTouchedFields] = useState(new Set<"name" | "configurationId" | "auditory" | "categoryId">());

  const categoriesForAuditory = useMemo(() => {
    switch (auditory) {
      case "PortfolioCompany": {
        return categories.filter((c) => c.type === objectDefinition.objectContactCategoryType);
      }
      case "Internal": {
        return categories.filter((c) => c.type === objectDefinition.internalUserPermissionsCategoryType);
      }
      default:
        return [];
    }
  }, [
    auditory,
    categories,
    objectDefinition.internalUserPermissionsCategoryType,
    objectDefinition.objectContactCategoryType,
  ]);

  const validationResults = useMemo(
    () => validateForm(name, auditory, categoryId, existingTemplateNames),
    [name, auditory, categoryId, existingTemplateNames]
  );

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setTouchedFields((prev) => new Set(prev).add("name"));
  };

  const handleConfigurationChange = (e: SelectChangeEvent) => {
    setConfigurationId(e.target.value);
    setTouchedFields((prev) => new Set(prev).add("configurationId"));
  };

  const handleAuditoryChange = (e: SelectChangeEvent) => {
    setAuditory(e.target.value as DataCollectionRequestAuditory);
    setCategoryId("");
    setTouchedFields((prev) => new Set(prev).add("auditory").add("categoryId"));
  };

  const handleCategoryChange = (e: SelectChangeEvent) => {
    setCategoryId(e.target.value);
    setTouchedFields((prev) => new Set(prev).add("categoryId"));
  };

  const handleSubmit = async () => {
    if (!auditory) {
      return;
    }

    setLoading(true);

    const [resp, error] = await createDataRequestTemplateVersion({
      objectType: objectDefinition.objectType,
      configurationId,
      name,
      auditory,
      objectAccessCategoryIds: [categoryId],
      recipientObjectIds: [],
    });

    setLoading(false);

    if (error) {
      logError(error, "[CreateDataRequestTemplateDialog] createDataRequestTemplateVersion");
      sendNotificationError("Failed to create template");
      return;
    }

    if (!resp.success) {
      sendNotificationError("Could not create template:\n" + resp.errors.join("\n"));
      return;
    }

    sendNotification("Template created successfully");
    onCreated(resp.templateVersion);
  };

  const selectedConfiguration = configurations.find((c) => c.id === configurationId);

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>New Template</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <TextField
              value={name}
              onChange={handleNameChange}
              label="Name"
              error={touchedFields.has("name") && !validationResults.nameResult.isValid}
              helperText={touchedFields.has("name") && validationResults.nameResult.error}
            />
          </FormControl>

          <Stack spacing={1} pt={2}>
            <Typography variant="subtitle1">Form</Typography>
            <Typography color="text.secondary">
              Choose a pre-configured form to define metrics and structure of this request.
            </Typography>
          </Stack>

          <FormControl fullWidth>
            <InputLabel id="template-configuration-select-label">Configuration</InputLabel>
            <Select fullWidth label="Configuration" value={configurationId} onChange={handleConfigurationChange}>
              {configurations.map((c) => (
                <MenuItem key={c.id} value={c.id}>
                  {c.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Stack direction="row" spacing={1}>
            <FormControl fullWidth>
              <InputLabel id="recipient-type-select-label">Recipient Type</InputLabel>
              <Select fullWidth label="Recipient Type" value={auditory} onChange={handleAuditoryChange}>
                {allDataCollectionRequestAuditoryValues.map((value) => (
                  <MenuItem key={value} value={value}>
                    {autoFormatCamelCase(value)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth>
              <InputLabel id="Category-select-label">Category</InputLabel>
              <Select
                fullWidth
                label="Category"
                value={categoryId}
                onChange={handleCategoryChange}
                disabled={!auditory}
              >
                {categoriesForAuditory.map((c) => (
                  <MenuItem key={c.id} value={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <InlinePropertyBox
            title="Configuration Settings"
            properties={getConfigurationSettingsProperties(selectedConfiguration)}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button variant="contained" loading={loading} onClick={handleSubmit} disabled={!validationResults.isValid}>
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateDataRequestTemplateDialog;
