import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../../api/axiosHelper";
import { ObjectMetricExtension } from "../../../../api/dataCollectionTypes";
import { MetricExtensionConfiguration } from "../../../../api/portfolioMonitoringTypes";
import { useNotificationContext } from "../../../../contexts/NotificationContext";
import useFetch from "../../../../hooks/useFetch";
import { logError } from "../../../../logging";
import { distinct, except } from "../../../../utilities/arrayHelper";
import DialogCloseButton from "../../../DialogeCloseButton";
import InlineLoader from "../../../inlineLoader/InlineLoader";
import { useDataSubmissionFormContext } from "../DataSubmissionFormContext";

interface Props {
  metricIds: string[];
  metricName: string | undefined;
  extensionConfigurationsPerMetricId?: Record<string, MetricExtensionConfiguration>; // Not needed if all static extensions are on the form already (i.e. cannot be added)
  addedExtensionIds: string[];
  allowCreatingExtensionValues: boolean;
  onClose: () => void;
  onAdded: (addedExtensions: ObjectMetricExtension[], isNew: boolean) => void;
}

const AddMetricExtensionDialog = ({
  metricIds,
  metricName,
  extensionConfigurationsPerMetricId,
  addedExtensionIds,
  allowCreatingExtensionValues,
  onClose,
  onAdded,
}: Props) => {
  const { metricExtensionsService, recipientObjectId } = useDataSubmissionFormContext();
  const { sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [extensionValue, setExtensionValue] = useState("");

  const searchObjectMetricExtensions = useCallback(
    () =>
      allowCreatingExtensionValues
        ? metricExtensionsService.searchObjectMetricExtensions({ objectId: recipientObjectId, metricIds })
        : Promise.resolve(createApiResponse([])), // No need to fetch company-spcecific extensions if creation is not allowed
    [allowCreatingExtensionValues, metricExtensionsService, metricIds, recipientObjectId]
  );

  const [existingObjectMetricExtensions, fetchError, { isFetching }] = useFetch(searchObjectMetricExtensions);

  const allExistingExtensions = useMemo(() => {
    const existingStaticExtensions: ObjectMetricExtension[] = Object.entries(
      extensionConfigurationsPerMetricId ?? {}
    ).flatMap(([metricId, extConfig]) =>
      extConfig.staticExtensions.map((ext) => ({
        id: ext.id,
        value: ext.value,
        metricId,
      }))
    );

    return [...(existingStaticExtensions ?? []), ...(existingObjectMetricExtensions ?? [])];
  }, [existingObjectMetricExtensions, extensionConfigurationsPerMetricId]);

  const extensionValueSelectOptions = useMemo(
    () =>
      distinct(allExistingExtensions.filter((ext) => !addedExtensionIds.includes(ext.id))?.map((e) => e.value)).sort(
        (a, b) => a.localeCompare(b)
      ),
    [addedExtensionIds, allExistingExtensions]
  );

  const handleAddClick = async () => {
    const existingExtensionsWithValue = allExistingExtensions.filter(
      (ext) => ext.value === extensionValue && metricIds.includes(ext.metricId)
    );

    const metricIdsOfExistingExtensions = existingExtensionsWithValue.map((e) => e.metricId);
    const metricIdsToCreateFor = except(metricIds, metricIdsOfExistingExtensions);

    if (metricIdsToCreateFor.length === 0) {
      onAdded(existingExtensionsWithValue, false);
      return;
    }

    if (!allowCreatingExtensionValues) {
      sendNotificationError("Selected extension value is not valid");
      return;
    }

    const createExtensions = withErrorHandling(metricExtensionsService.createObjectMetricExtensions);

    setSaving(true);

    const [newExtensions, error] = await createExtensions({
      objectId: recipientObjectId,
      metricExtensions: metricIdsToCreateFor.map((metricId) => ({ metricId, value: extensionValue })),
    });

    setSaving(false);

    if (error) {
      logError(error, "[AddMetricExtensionDialog] createMetricExtension");
      sendNotificationError("Could not create metric extension");
      return;
    }

    onAdded([...existingExtensionsWithValue, ...newExtensions], true);
  };

  const isNewValueValid = extensionValue.trim().length > 0;
  const placeholder = allowCreatingExtensionValues ? "Type new name or select from the list" : "Select from the list";

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{metricName ? `Add Extension for ${metricName}` : "Add Extension"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        {fetchError ? (
          <Typography color="error" py={1}>
            Could not load existing metric extensions
          </Typography>
        ) : isFetching || existingObjectMetricExtensions === undefined ? (
          <InlineLoader />
        ) : (
          <Autocomplete
            freeSolo={allowCreatingExtensionValues}
            fullWidth
            value={extensionValue}
            options={extensionValueSelectOptions}
            noOptionsText="No existing extensions yet"
            renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
            onInputChange={(_, value) => {
              if (value) {
                setExtensionValue(value);
              }
            }}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isFetching || !!fetchError || !isNewValueValid}
          onClick={handleAddClick}
          loading={isSaving}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMetricExtensionDialog;
