import CloseIcon from "@mui/icons-material/Close";
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import adminApi from "../../../../api/adminApi";
import { useLocalization } from "../../../../hooks/useLocalization";
import DashedArrowIconLeft from "../../../../icons/DashedArrowIconLeft";
import DashedArrowIconRight from "../../../../icons/DashedArrowIconRight";
import DataIcon from "../../../../icons/DataIcon";
import aws from "../../../../images/aws.png";

const backupNow = withErrorHandling(adminApi.runDataBackupNow);

interface Props {
  onCancel: () => void;
  onBackupStarted: () => void;
  onOperationInProgress: () => void;
  onConnectionFailed: (msg: string) => void;
}

const BackupNowDialog = ({ onCancel, onBackupStarted, onOperationInProgress, onConnectionFailed }: Props) => {
  const {
    dataBackup: { backup_now_dialog: locale },
  } = useLocalization();
  const { sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  const handleRunBackup = async () => {
    setLoading(true);
    const [backupResponse, error] = await backupNow();
    if (error) {
      sendNotificationError(locale.backup_failed);
    } else if (backupResponse.previousSyncInProgress) {
      onOperationInProgress();
    } else if (backupResponse.connectionFailed) {
      onConnectionFailed(locale.backup_connection_failed);
    } else if (backupResponse.success) {
      onBackupStarted();
    } else {
      sendNotificationError(locale.backup_failed);
    }
    setLoading(false);
  };

  return (
    <Dialog open={true} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" noWrap>
            {locale.title}
          </Typography>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={(t) => ({ px: t.spacing(3), pb: t.spacing(2.5) })}>
        <Stack sx={(t) => ({ pt: t.spacing(2.5) })} spacing={4}>
          <Stack gap={2.5} flexDirection={"row"} justifyContent={"center"} alignItems={"center"}>
            <DataIcon />
            <Stack spacing={1}>
              <DashedArrowIconRight sx={{ width: 76, height: 18 }} />
              <DashedArrowIconLeft sx={(t) => ({ width: 76, height: 18, color: t.palette.dialogIcon })} />
            </Stack>
            <Avatar src={aws} variant="rounded" alt="logo" sx={{ width: 64, height: 64 }} />
          </Stack>
          <Stack spacing={2} textAlign={"center"}>
            <Typography variant="subtitle2">{locale.subtitle}</Typography>
            <Typography>{locale.description}</Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={(t) => ({ py: t.spacing(2), px: t.spacing(3), columnGap: t.spacing(1) })}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          {locale.cancel}
        </Button>
        <Button variant="contained" color="primary" loading={loading} onClick={handleRunBackup}>
          {locale.submit}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BackupNowDialog;
