import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import {
  combineValidators,
  regexValidator,
  requiredValidator,
  uniqueValidator,
} from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import UserConsentCategorySelect from "./UserConsentCategorySelect";
import { useUserConsentsSectionContext } from "./UserConsentsSectionContext";

interface Props {
  onClose: () => void;
  onCreated: (newConsentName: string) => void;
  existingConsentNames: string[];
}

const createUserConsent = withErrorHandling(adminApi.createUserConsent);

const CreateUserConsentDialog = ({ onClose, onCreated, existingConsentNames }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const { categories } = useUserConsentsSectionContext();

  const validateConsentName = useMemo(
    () =>
      combineValidators(
        requiredValidator,
        regexValidator(/^[a-zA-Z0-9\s]+$/, "Name can only contain letters, numbers and spaces"),
        uniqueValidator(
          "User consent with such name already exists",
          existingConsentNames.map((n) => n.toLowerCase())
        )
      ),
    [existingConsentNames]
  );

  const [name, setName] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [categoryId, setCategoryId] = useState<string>();
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);
    const [newConsentName, error] = await createUserConsent({ name, categoryId });
    setSaving(false);

    if (error) {
      logError(error, "[CreateUserConsentDialog] deleteUserConsent");
      sendNotificationError("Could not add user consent");
      return;
    }

    onCreated(newConsentName);
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIsDirty(true);
    setName(e.target.value);
  };

  const nameValidationResult = validateConsentName(name.toLowerCase());

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>New User Consent</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <TextField
            fullWidth
            label="Name"
            value={name}
            onChange={handleNameChange}
            error={isDirty && !nameValidationResult.isValid}
            helperText={isDirty ? nameValidationResult.error : undefined}
          />

          <UserConsentCategorySelect
            categoryId={categoryId}
            onChange={(newCategoryId) => setCategoryId(newCategoryId)}
            categories={categories}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={saving} onClick={handleSubmit} disabled={!nameValidationResult.isValid}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUserConsentDialog;
