import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo } from "react";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import BackdropLoader from "../../../../../../shared/components/fullScreenLoader/BackdropLoader";
import ChipsMultilineInput from "../../../../../../shared/components/inputs/ChipsMultilineInput";
import { emailValidator } from "../../../../../../shared/utilities/validators";
import { useMembersContext } from "../MembersContext";
import AccessSection from "./AccessSection";
import CompaniesManageSection from "./CompaniesManageSection";
import DeveloperPortal from "./DeveloperPortal";
import { useInviteMembersContext } from "./InviteMembersContext";
import ManagedMemberDetails from "./ManagedMemberDetails";
import OrganizationRoleSelector from "./OrganizationRoleSelector";
import PermissionsBlankIcon from "./PermissionsBlankIcon";

const InviteMembersContainer = () => {
  const theme = useTheme();
  const borderLineStyle = `1px ${theme.palette.divider} solid`;

  const { isSending, showInviteMembersDialog, members, companiesList } = useMembersContext();

  const {
    emails,
    updateEmails,
    selectedCompanyAccess,
    isValidConfiguration,
    handleClose,
    sendInvites,
    getDialogTitle,
    managedMember,
    applyMemberChanges,
    isCurrentUser,
  } = useInviteMembersContext();

  const devPortalAccessibleCompanies = useMemo(() => {
    const accessibleCompanies = members.find((m) => m.userId === managedMember?.userId)?.accessToDeveloperPortal || [];
    return accessibleCompanies.map((companyCode) => {
      const companyData = companiesList.find((c) => c.value === companyCode);
      return { title: companyData?.label || "", icon: companyData?.iconSrc, code: companyData?.value || "" };
    });
  }, [members, managedMember, companiesList]);

  return (
    <Dialog
      open={showInviteMembersDialog}
      fullWidth
      maxWidth="xl"
      scroll="paper"
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          backgroundColor: grey[50],
          height: "88vh",
          top: 12,
        },
      }}
    >
      <DialogTitle>{getDialogTitle()}</DialogTitle>
      <DialogCloseButton onClick={handleClose} />
      <Divider />

      <DialogContent sx={{ p: 0 }}>
        <Grid2 container sx={{ height: "100%" }}>
          <Grid2
            size={{ xs: 7, md: 8, lg: 9, xl: 9.3 }}
            alignItems="start"
            sx={{ borderRight: borderLineStyle, p: 3, height: "100%", maxHeight: "100%", overflowY: "auto" }}
          >
            <Stack gap={1.5}>
              <Typography variant="subtitle1">{managedMember ? "User" : "Send invite to users"}</Typography>
              {!managedMember && (
                <ChipsMultilineInput
                  values={emails}
                  placeholder="Enter one or multiple emails, comma or space separated"
                  onChange={updateEmails}
                  inputValidator={emailValidator}
                  freeSolo
                  removeDuplicatesOnPaste={false}
                  restrictedItems={members.map((member) => member.email)}
                  restrictedItemsHelperText="User with such email is already registered"
                />
              )}
              {managedMember && (
                <ManagedMemberDetails
                  name={managedMember.name}
                  email={managedMember.email}
                  isCurrentUser={isCurrentUser(managedMember.userId)}
                />
              )}
              <Typography sx={{ mt: 1 }} variant="subtitle1">
                Organization Role
              </Typography>
              <OrganizationRoleSelector />
              {devPortalAccessibleCompanies.length > 0 && (
                <>
                  <Typography sx={{ mt: 2.5, mb: 0.5 }} variant="subtitle2">
                    Developer portal
                  </Typography>
                  <DeveloperPortal accessibleCompanies={devPortalAccessibleCompanies} />
                </>
              )}
              <CompaniesManageSection />
            </Stack>
          </Grid2>
          <Grid2
            size="grow"
            sx={{
              p: 3,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {!selectedCompanyAccess && <PermissionsBlankIcon />}
            {selectedCompanyAccess && <AccessSection companyAccess={selectedCompanyAccess} />}
          </Grid2>
        </Grid2>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        {!managedMember && (
          <Button
            variant="outlined"
            color="primary"
            disabled={!isValidConfiguration}
            loading={isSending}
            onClick={sendInvites}
            endIcon={<ArrowForwardRoundedIcon />}
          >
            Send Invite
          </Button>
        )}
        {managedMember && (
          <Button
            variant="outlined"
            color="primary"
            disabled={!isValidConfiguration}
            loading={isSending}
            onClick={applyMemberChanges}
          >
            Apply
          </Button>
        )}
      </DialogActions>
      <BackdropLoader open={isSending} />
    </Dialog>
  );
};

export default InviteMembersContainer;
