import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import { arraysHaveSameItems } from "../../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../../api/adminApi";
import { FundraisingAccessItem } from "../../../../../../api/types/fundraisingTypes";
import AccessEditor, { AccessValue } from "../../../../../common/AccessEditor";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";

interface Props {
  editedAccessItem: FundraisingAccessItem;
  onSubmit: () => void;
  onClose: () => void;
}

const getInitialAccessValue = (editedAccessItem: FundraisingAccessItem): AccessValue => ({
  selectedRole: editedAccessItem.roles[0] ?? "LP_Member",
  selectedCategoryIds: editedAccessItem.categoryIds,
});

const updateFundInvestor = withErrorHandling(adminApi.updateFundInvestor);

const EditFundraisingAccessDialog = ({ editedAccessItem, onSubmit, onClose }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const { fundraisingCategories } = useFundraisingDetailsPageContext();

  const [accessValue, setAccessValue] = useState<AccessValue>(getInitialAccessValue(editedAccessItem));
  const [isSaving, setSaving] = useState(false);

  const handleSave = async () => {
    setSaving(true);

    const [, error] = await updateFundInvestor(editedAccessItem.fundInvestorId, {
      existingContacts: [
        {
          contactId: editedAccessItem.contactId,
          roles:
            accessValue.selectedRole === "None" || accessValue.selectedRole === undefined
              ? []
              : [accessValue.selectedRole],
          externalCategoryIds: accessValue.selectedCategoryIds,
        },
      ],
      newContacts: [],
      removedContactIds: [],
    });

    setSaving(false);

    if (error) {
      logError(error, "[EditFundraisingAccessDialog] updateFundInvestor");
      sendNotificationError("Failed to update access");
      return;
    }

    onSubmit();
    onClose();
  };

  const handleAccessChange = (update: Partial<AccessValue>) => {
    setAccessValue((prev) => ({ ...prev, ...update }));
  };

  const isDirty = !arraysHaveSameItems(editedAccessItem.categoryIds, accessValue.selectedCategoryIds);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Edit {editedAccessItem.contactName}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <AccessEditor
          value={accessValue}
          onChange={handleAccessChange}
          roleOptions={["LP_Member"]}
          categoryOptions={fundraisingCategories.map((c) => ({ id: c.externalId, label: c.name }))}
        />
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={isSaving} onClick={handleSave} disabled={!isDirty}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditFundraisingAccessDialog;
