import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { ApplicationLogs } from "../../../api/types/logTypes";
import LogDetails from "./LogDetails";

interface Props {
  open: boolean;
  onClose: () => void;
  logDetails?: ApplicationLogs;
  hideSeverityColumn?: boolean;
}

const LogDetailsDialog = ({ open, onClose, logDetails, hideSeverityColumn }: Props) => (
  <Dialog open={open} onClose={onClose} slotProps={{ paper: { sx: (theme) => ({ maxWidth: theme.spacing(100) }) } }}>
    <DialogTitle>Run Details</DialogTitle>
    <DialogCloseButton onClick={onClose} />
    <Divider />
    <DialogContent sx={(theme) => ({ px: theme.spacing(4), pb: theme.spacing(8) })}>
      {logDetails && <LogDetails logDetails={logDetails} hideSeverityColumn={hideSeverityColumn} />}
    </DialogContent>
  </Dialog>
);

export default LogDetailsDialog;
