import { Button } from "@mui/material";

interface Props {
  isImportInProgress?: boolean;
  isImporting?: boolean;
  importDisabled?: boolean;
  onClose: () => void;
  onStartImport: () => void;
}

const ImportPassthroughDataDialogActions = ({
  isImportInProgress,
  onClose,
  onStartImport,
  isImporting,
  importDisabled,
}: Props) => {
  return isImportInProgress ? (
    <Button variant="contained" color="primary" onClick={onClose}>
      Close
    </Button>
  ) : (
    <>
      <Button variant="text" color="secondary" onClick={onClose}>
        Cancel
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={importDisabled}
        onClick={onStartImport}
        loading={isImporting}
      >
        Start Import
      </Button>
    </>
  );
};

export default ImportPassthroughDataDialogActions;
