import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { FileTag } from "../../../../api/types/fileTypes";
import { getEntityTitle } from "../../../common/files/fileDetailsHelper";
import TagEditForm from "./TagEditForm";
import { TagFormState, TagSettingsDialogState } from "./dialogState";

interface Props {
  dialogState: TagSettingsDialogState;
  onFormChange: (form: TagFormState) => void;
  onCancel: () => void;
  onSubmit: (newTag: FileTag) => void;
}

const createFileTag = withErrorHandling(adminApi.createFileTag);

const AddTagDialog = ({ dialogState, onFormChange, onCancel, onSubmit }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    if (!dialogState.form.isValid || !dialogState.form.entityType) {
      return;
    }

    setSaving(true);
    const entityType = dialogState.form.entityType;
    const [resp, error] = await createFileTag({ name: dialogState.form.name.trim(), entityTypes: [entityType] });
    if (error) {
      logError(error, "createFileTag");
      sendNotificationError("Could not create file tag");
    } else {
      sendNotification(`Tag "${dialogState.form.name}" successfully added for entity ${getEntityTitle(entityType)}`);
      onSubmit(resp);
    }
    setSaving(false);
  };

  return (
    <Dialog open={dialogState.openDialog === "add"} onClose={onCancel} sx={{ ".MuiPaper-root": { width: "32rem" } }}>
      <DialogTitle>Add Tag</DialogTitle>
      <DialogCloseButton onClick={onCancel} />
      <DialogContent sx={(t) => ({ px: t.spacing(4) })}>
        <TagEditForm form={dialogState.form} onChange={onFormChange} />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!dialogState.form.isValid}
          variant="contained"
          color="primary"
          loading={saving}
          onClick={handleSubmit}
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTagDialog;
