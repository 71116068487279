import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import adminApi, { ApiGatewaySubscription } from "../../../api/adminApi";
import ApiGatewaySubscriptionForm, { SubscriptionFormState } from "./ApiGatewaySubscriptionForm";

interface Props {
  open: boolean;
  subscription: ApiGatewaySubscription | undefined;
  onCancel: () => void;
  onSubmit: (updatedSubscription: ApiGatewaySubscription) => void;
}

const updateApiGatewaySubscription = withErrorHandling(adminApi.updateApiGatewaySubscription);

const EditSubscriptionDialog = ({ open, onCancel, onSubmit, subscription }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  useEffect(() => {
    if (subscription) {
      setForm({
        user: { userId: subscription.userId, username: subscription.username, email: subscription.email },
        description: subscription.description || "",
        type: subscription.type,
      });
    }
  }, [subscription]);

  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<SubscriptionFormState>({ user: null, description: "", type: "EntriliaApi" });

  const isDirty = () => subscription && subscription.description?.trim() !== form.description.trim();

  const handleFormChange = (value: SubscriptionFormState) => setForm(value);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (!subscription || !isDirty()) {
      return;
    }

    setSaving(true);
    const [updatedSubscription, error] = await updateApiGatewaySubscription(subscription.id, {
      description: form.description,
    });
    setSaving(false);

    if (error) {
      logError(error, "[EditApiGatewaySubscriptionDialog]");
      sendNotificationError(`Failed to update subscription for ${subscription.username}`);
      return;
    }

    sendNotification(`Subscription for ${subscription.username} successfully updated`);
    onSubmit(updatedSubscription);
  };

  return (
    <Dialog open={open} onClose={handleCancel} sx={{ ".MuiPaper-root": { width: "32rem" } }}>
      <DialogTitle>Edit Subscription</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <Divider />
      <DialogContent sx={(t) => ({ px: t.spacing(4) })}>
        <ApiGatewaySubscriptionForm
          users={form.user ? [form.user] : []}
          form={form}
          onChange={handleFormChange}
          disableUserSelection
          disableTypeSelection
        />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" loading={saving} onClick={handleSubmit} disabled={!isDirty()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditSubscriptionDialog;
