import {
  Box,
  Button,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { ClientInfoBase } from "../../../../../../shared/api/clientTypes";
import DialogHeader from "../../../../../../shared/components/dialog/DialogHeader";
import PromptDialog from "../../../../../../shared/components/dialog/PromptDialog";
import { useSharedReportingLocalization } from "../../../../../../shared/reporting/hooks/useLocalization";

interface Props {
  companies: ClientInfoBase[];
  onClose: () => void;
  onCreate: (clientCode: string) => void;
}

export default function NewTemplateDialog({ companies, onClose, onCreate }: Props) {
  const { report_templates: locale } = useSharedReportingLocalization();
  const [selectedCompany, setSelectedCompany] = useState<string>(() => companies.at(0)?.clientCode || "");

  const handleChange = useCallback((clientCode: string) => {
    setSelectedCompany(clientCode);
  }, []);

  return (
    <PromptDialog open={true} sx={{ width: 517, minHeight: 212 }}>
      <DialogHeader text="New Template" onClose={onClose} />
      <Stack
        sx={{
          flexDirection: "column",
          flex: 1,
          gap: 2,
          alignItems: "flex-start",
          justifyContent: "center",
          pt: 1,
          pb: 2.5,
          px: 3,
        }}
      >
        <Typography sx={{ color: "text.primary" }}>{locale.datasource_disclaimer}</Typography>
        <FormControl fullWidth>
          <InputLabel id="company-select-label">Company</InputLabel>
          <Select
            labelId="company-select-label"
            label="Company"
            value={selectedCompany}
            renderValue={(value) => {
              const logo = companies.find((c) => c.clientCode === value)?.branding.logoMarkUrl;
              return (
                <Box sx={{ display: "flex", alignItems: "center", pl: 0.25, gap: 1.5 }} width={20} height={20}>
                  {logo && (
                    <Box display="flex" justifyContent="center" alignItems="center" bgcolor="white">
                      <img alt="Client Logo" style={{ width: "20px", height: "20px" }} src={logo} />
                    </Box>
                  )}
                  <Typography>{companies.find((c) => c.clientCode === value)?.title || ""}</Typography>
                </Box>
              );
            }}
            onChange={(e) => handleChange(e.target.value)}
          >
            {companies.map((company, i) => (
              <MenuItem key={i} value={company.clientCode} sx={{ py: 0.5, gap: 1.5 }}>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width={20}
                  height={20}
                  p={0.5}
                  bgcolor="white"
                >
                  <img alt="Client Logo" style={{ width: "20px", height: "20px" }} src={company.branding.logoMarkUrl} />
                </Box>
                <Typography>{company.title}</Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <DialogActions sx={{ px: 2.5, py: 1.5 }}>
        <Button variant="text" color="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={!selectedCompany}
          onClick={() => selectedCompany && onCreate(selectedCompany)}
        >
          Create
        </Button>
      </DialogActions>
    </PromptDialog>
  );
}
