import { Button, Checkbox, Dialog, FormControlLabel, FormGroup, Theme, useMediaQuery } from "@mui/material";
import { useState } from "react";
import ConsentAgreementLabel from "./ConsentAgreementLabel";
import ConsentDialogBody from "./ConsentDialogBody";
import { ConsentAgreement } from "./types";

interface Props {
  open: boolean;
  saving: boolean;
  logoUrl?: string | undefined;
  clientTitle?: string;
  consentAgreement: ConsentAgreement;
  portalTitle: string;
  onAccept: (consentAgreement: ConsentAgreement) => void;
}

const ConsentAgreementDialog = ({
  open,
  saving,
  logoUrl,
  clientTitle,
  consentAgreement,
  portalTitle,
  onAccept,
}: Props) => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const [acceptAgreement, setAcceptAgreement] = useState(false);

  const handleAgreementAccepted = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAcceptAgreement(event.target.checked);
  };

  const handleAcceptClick = () => {
    onAccept(consentAgreement);
    setAcceptAgreement(false);
  };

  return (
    <Dialog
      open={open}
      scroll="paper"
      fullScreen={isSmallScreen}
      fullWidth
      maxWidth="md"
      sx={{ "& .MuiBackdrop-root": { backdropFilter: "blur(2px)" } }}
      slotProps={{ paper: { sx: consentAgreement.content ? { minHeight: "50vh" } : undefined } }}
    >
      <ConsentDialogBody
        title={`Welcome to ${portalTitle}`}
        logoUrl={logoUrl}
        clientTitle={clientTitle}
        consentContent={consentAgreement.content}
        acceptFormGroup={
          <FormGroup sx={{ my: "1rem" }}>
            <FormControlLabel
              control={
                <Checkbox
                  disabled={saving}
                  color="primary"
                  checked={acceptAgreement || saving}
                  onChange={handleAgreementAccepted}
                />
              }
              label={
                <ConsentAgreementLabel
                  consentName={consentAgreement.name}
                  url={consentAgreement.url}
                  customAgreementText={consentAgreement.customAgreementText}
                />
              }
            />
          </FormGroup>
        }
        saveButton={
          <Button
            disabled={!acceptAgreement || saving}
            fullWidth
            variant="contained"
            sx={{ height: "2.25rem", width: isSmallScreen ? undefined : "7rem" }}
            onClick={handleAcceptClick}
            loading={saving}
          >
            Continue
          </Button>
        }
      />
    </Dialog>
  );
};

export default ConsentAgreementDialog;
