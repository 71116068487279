import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import { Box, Button, Grow, MenuList, Paper, Popper } from "@mui/material";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useRef, useState } from "react";
import ActionMenuItem from "./ActionMenuItem";

interface Props {
  primaryOption: SplitButtonOption;
  secondaryOptions: SplitButtonOption[];
  saving?: boolean;
  primaryActionStartIcon?: React.ReactNode;
}
export default function SplitButton({ primaryOption, secondaryOptions, saving, primaryActionStartIcon }: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const handleClose = (event: Event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Box
        ref={anchorRef}
        sx={{
          borderRadius: "4px",
          display: "inline-flex",
          ...getStylesWithOptions(secondaryOptions.length > 0),
        }}
      >
        <Button
          startIcon={primaryActionStartIcon}
          variant="contained"
          onClick={() => primaryOption.handler()}
          loading={saving}
          disabled={saving}
          sx={{ minWidth: "auto" }}
        >
          {primaryOption.title}
        </Button>
        {secondaryOptions.length > 0 && (
          <Button
            disabled={saving}
            variant="contained"
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select report save option"
            aria-haspopup="menu"
            onClick={() => setOpen((prevOpen) => !prevOpen)}
            sx={{ minWidth: "unset", p: 0.75 }}
          >
            <ExpandMoreRoundedIcon />
          </Button>
        )}
      </Box>
      <Popper
        sx={{
          zIndex: 1,
          minWidth: anchorRef.current?.clientWidth || 126,
        }}
        open={open}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement={"bottom-end"}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem>
                  {secondaryOptions.map((option, i) => (
                    <ActionMenuItem
                      key={i}
                      title={option.title}
                      icon={option.icon ? <option.icon fontSize="small" /> : <></>}
                      onClick={() => {
                        setOpen(false);
                        secondaryOptions[i]?.handler();
                      }}
                      disabled={option.disabled}
                    />
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

const getStylesWithOptions = (hasOptions: boolean) =>
  hasOptions
    ? {
        "button:first-of-type": {
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
        },
        "button:last-of-type": {
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
        },
      }
    : {};

export type SplitButtonOption = {
  title: string;
  icon?: React.ElementType;
  handler: () => void;
  disabled?: boolean;
};
