import { Button, FormControlLabel, Stack, Switch } from "@mui/material";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import crmConnectorsApi from "../../../api/crmConnectorsApi";
import { useClientContext } from "../../../context/ClientContext";
import { pageRoutes } from "../../../routes";
import { useCrmConnectorContext } from "./CrmConectorContext";
import DeleteConnectionDialog from "./DeleteConnectionDialog";

interface Props {
  hideTestConnection?: boolean;
  hideFullResync?: boolean;
  hideDelete?: boolean;
  hideDataSyncToggle?: boolean;
}

const CrmActions = ({ hideTestConnection, hideFullResync, hideDelete, hideDataSyncToggle }: Props) => {
  const { clientCode } = useClientContext();

  const navigate = useNavigate();

  const {
    crmApiName,
    syncEnabled,
    onSyncEnabledUpdate,
    testConnectionState,
    onTestConnectionStateUpdate,
    fullResyncState,
    onFullResyncStateUpdate,
  } = useCrmConnectorContext();

  const testConnection = useMemo(() => withErrorHandling(crmConnectorsApi[crmApiName].testConnection), [crmApiName]);

  const fullResync = useMemo(() => withErrorHandling(crmConnectorsApi[crmApiName].startFullResyncJob), [crmApiName]);

  const [deleteConnectionPrompt, setDeleteConnectionPrompt] = useState(false);
  const handleDeleteClick = () => setDeleteConnectionPrompt(true);
  const handleDeleteCancel = () => setDeleteConnectionPrompt(false);

  const handleDeleteSuccess = () => {
    setDeleteConnectionPrompt(false);
    navigate(`/${clientCode}/${pageRoutes.settings}/${pageRoutes.settingsIntegrations}`);
  };

  const handleDataSyncToggle = async () => {
    const enabled = !syncEnabled;
    onSyncEnabledUpdate?.(enabled);
    await crmConnectorsApi[crmApiName].postDataSyncEnabled(enabled);
  };

  const handleTestConnection = async () => {
    onTestConnectionStateUpdate({ status: "loading" });

    const [testConnectionResult, error] = await testConnection();
    if (error) {
      onTestConnectionStateUpdate({ status: "error", errorMessage: error.message });
      return;
    }

    onTestConnectionStateUpdate(
      testConnectionResult.success
        ? { status: "success" }
        : { status: "error", errorMessage: testConnectionResult.message }
    );
  };

  const handleFullResync = async () => {
    onFullResyncStateUpdate({ status: "starting" });

    const [startFullResyncResult, error] = await fullResync();
    if (error) {
      onFullResyncStateUpdate({ status: "error", errorMessage: error.message });
      return;
    }

    onFullResyncStateUpdate(
      startFullResyncResult.result
        ? { status: "success" }
        : { status: "error", errorMessage: "Operation failed to start" }
    );
  };

  return (
    <>
      <Stack direction="row" spacing={2.5} mt={3.5} px={3} py={2}>
        {!hideTestConnection && (
          <Button
            loading={testConnectionState.status === "loading"}
            variant="outlined"
            color="secondary"
            onClick={handleTestConnection}
          >
            Test Connection
          </Button>
        )}
        {!hideFullResync && (
          <Button
            loading={fullResyncState.status === "starting"}
            variant="outlined"
            color="secondary"
            onClick={handleFullResync}
          >
            Full Re-Synchronization
          </Button>
        )}
        {!hideDelete && (
          <Button variant="outlined" color="secondary" onClick={handleDeleteClick}>
            Delete
          </Button>
        )}
        {!hideDataSyncToggle && (
          <FormControlLabel
            control={<Switch checked={syncEnabled} onChange={handleDataSyncToggle} />}
            label="Data Synchronization Enabled"
          />
        )}
      </Stack>

      <DeleteConnectionDialog
        open={deleteConnectionPrompt}
        onSuccess={handleDeleteSuccess}
        onCancel={handleDeleteCancel}
      />
    </>
  );
};

export default CrmActions;
