import LanguageIcon from "@mui/icons-material/LanguageRounded";
import TodayIcon from "@mui/icons-material/TodayRounded";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers-pro";
import { formatISO, isAfter, parseISO } from "date-fns";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import {
  DataCollectionRequestDetails,
  DataCollectionRequestStatus,
} from "../../../../../api/types/dataCollectionTypes";
import LanguageOffIcon from "../../../../../icons/LanguageOffIcon";
import BorderedRadioLabel from "../../../../common/BorderedRadioLabel";

const publishDataCollectionRequest = withErrorHandling(adminApi.publishDataCollectionRequest);
const cancelScheduledPublishOfDataRequest = withErrorHandling(adminApi.cancelScheduledPublishOfDataRequest);

interface Props {
  dataRequestId: string;
  dataRequestStatus: DataCollectionRequestStatus;
  dataRequestScheduledAt: string | undefined;
  onClose: () => void;
  onSaved: (dataRequest: DataCollectionRequestDetails) => void;
}

type PublishOption = "publish" | "schedule" | "unschedule";

const PublishDataRequestDialog = ({
  dataRequestId,
  dataRequestStatus,
  dataRequestScheduledAt,
  onClose,
  onSaved,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);
  const [sendEmailNotifications, setSendEmailNotifications] = useState(true);
  const [selectedOption, setSelectedOption] = useState<PublishOption>(
    dataRequestStatus === "Scheduled" ? "schedule" : "publish"
  );
  const [scheduleDateTime, setScheduleDateTime] = useState<Date | null>(
    dataRequestScheduledAt ? parseISO(dataRequestScheduledAt) : null
  );

  const handlePublish = async () => {
    setLoading(true);

    const payload = {
      sendEmailNotifications,
      scheduledToBePublishedAt:
        selectedOption === "schedule" && scheduleDateTime !== null ? formatISO(scheduleDateTime) : undefined,
    };

    const [resp, error] = await publishDataCollectionRequest(dataRequestId, payload);

    setLoading(false);

    if (error) {
      logError(error, "[PublishDataRequestDialog] publishDataCollectionRequest");
      sendNotificationError("Could not update data request");
      return;
    }

    sendNotification(
      selectedOption === "schedule" ? "Data request scheduled successfully" : "Data request published successfully"
    );
    onSaved(resp);
  };

  const handleUnschedule = async () => {
    setLoading(true);

    const [resp, error] = await cancelScheduledPublishOfDataRequest(dataRequestId);

    setLoading(false);

    if (error) {
      logError(error, "[PublishDataRequestDialog] cancelScheduledPublishOfDataRequest");
      sendNotificationError("Could not updated data request");
      return;
    }

    sendNotification("The scheduled publishing canceled successfully");
    onSaved(resp);
  };

  const handleSubmit = async () => {
    if (selectedOption === "unschedule") {
      await handleUnschedule();
    } else {
      await handlePublish();
    }
  };

  const isValid =
    selectedOption === "schedule" ? scheduleDateTime !== null && isAfter(scheduleDateTime, new Date()) : true;

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Publishing</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <Stack spacing={1}>
          <FormControl>
            <RadioGroup
              value={selectedOption}
              onChange={(e) => setSelectedOption(e.target.value as PublishOption)}
              sx={{ rowGap: 1 }}
            >
              <BorderedRadioLabel<PublishOption>
                value="publish"
                selectedValue={selectedOption}
                sx={{ pl: 2, py: 1 }}
                label={
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar variant="rounded">
                      <LanguageIcon color={selectedOption === "publish" ? "primary" : "action"} />
                    </Avatar>
                    <Box>
                      <Typography variant="subtitle2">Publish immediately</Typography>
                      <Typography color="text.secondary">
                        The request will be sent immediately to the recipients.
                      </Typography>
                    </Box>
                  </Stack>
                }
              />

              <BorderedRadioLabel<PublishOption>
                value="schedule"
                selectedValue={selectedOption}
                sx={{ pl: 2, py: 1 }}
                label={
                  <Stack spacing={2}>
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar variant="rounded">
                        <TodayIcon color={selectedOption === "schedule" ? "primary" : "action"} />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2">Schedule for later</Typography>
                        <Typography color="text.secondary">
                          Set up schedule to send your request at specific time.
                        </Typography>
                      </Box>
                    </Stack>

                    {selectedOption === "schedule" && (
                      <DateTimePicker
                        label="Date and Time"
                        value={scheduleDateTime}
                        onChange={(newValue) => setScheduleDateTime(newValue)}
                        disablePast
                        timeSteps={{ hours: 1, minutes: 30 }}
                      />
                    )}
                  </Stack>
                }
              />

              {dataRequestStatus === "Scheduled" && (
                <BorderedRadioLabel<PublishOption>
                  value="unschedule"
                  selectedValue={selectedOption}
                  sx={{ pl: 2, py: 1 }}
                  label={
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Avatar variant="rounded">
                        <LanguageOffIcon color={selectedOption === "unschedule" ? "primary" : "action"} />
                      </Avatar>
                      <Box>
                        <Typography variant="subtitle2">Cancel schedule</Typography>
                        <Typography color="text.secondary">
                          The submissions will be saved as a draft, and can be published later.
                        </Typography>
                      </Box>
                    </Stack>
                  }
                />
              )}
            </RadioGroup>
          </FormControl>

          {selectedOption !== "unschedule" && (
            <FormControlLabel
              label={
                selectedOption === "publish"
                  ? "Send notification emails"
                  : "Send notification emails on publishing date"
              }
              control={
                <Checkbox
                  checked={sendEmailNotifications}
                  onChange={(_, checked) => setSendEmailNotifications(checked)}
                />
              }
            />
          )}
        </Stack>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={loading} onClick={handleSubmit} disabled={!isValid}>
          {selectedOption === "publish" ? "Publish" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PublishDataRequestDialog;
