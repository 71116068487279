import { MenuItemProps } from "@mui/base";
import ChevronRightIcon from "@mui/icons-material/ChevronRightRounded";
import MoreIcon from "@mui/icons-material/MoreHoriz";
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  TypographyProps,
} from "@mui/material";
import React, { useState } from "react";

interface Props extends Omit<ButtonComponentProps, "onClick"> {
  onOpen?: () => void;
  onClose?: () => void;
  items: ActionMenuItemProps[];
}

interface ButtonComponentProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  icon?: React.ReactNode;
  text?: string;
  variant?: "text" | "outlined" | "contained";
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  disabled?: boolean;
  loading?: boolean;
}

interface ActionMenuItemProps {
  label: string;
  onClick?: () => void;
  icon?: React.ReactNode;
  disabled?: boolean;
  props?: MenuItemProps;
  textProps?: TypographyProps;
  subMenuItems?: ActionMenuItemProps[];
  tooltip?: string;
  component?: React.ReactNode;
}

const ButtonComponent = ({ onClick, text, color, disabled, icon, variant, loading }: ButtonComponentProps) => {
  if (text) {
    return (
      <Button onClick={onClick} loading={loading} disabled={disabled} variant={variant} color={color} startIcon={icon}>
        {text}
      </Button>
    );
  }

  return (
    <IconButton onClick={onClick} color={color} disabled={disabled}>
      {icon ?? <MoreIcon />}
    </IconButton>
  );
};

const ActionsMenuButton = ({ items, onOpen, onClose, icon, text, variant, color, disabled, loading }: Props) => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null);
  const [subMenuItemAnchor, setSubMenuItemAnchor] = useState<HTMLElement | null>(null);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
    onOpen?.();
  };

  const handleItemClick = (item: ActionMenuItemProps) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    item.onClick?.();
    if (item.subMenuItems?.length) {
      setSubMenuItemAnchor(e.currentTarget);
    } else {
      setMenuAnchor(null);
    }
  };

  const handleSubItemClick = (item: ActionMenuItemProps) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.stopPropagation();
    item.onClick?.();
    setSubMenuItemAnchor(null);
    setMenuAnchor(null);
  };

  const menuSlotProps = {
    paper: { sx: { minWidth: "12rem" } },
  };

  return (
    <>
      <ButtonComponent
        onClick={handleButtonClick}
        text={text}
        color={color}
        disabled={disabled}
        icon={icon}
        variant={variant}
        loading={loading}
      />

      <Menu
        anchorEl={menuAnchor}
        open={Boolean(menuAnchor)}
        onClose={() => {
          setMenuAnchor(null);
          onClose?.();
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        slotProps={menuSlotProps}
        closeAfterTransition
        disableRestoreFocus
      >
        {items.map((item, index) =>
          item.component ? (
            item.component
          ) : (
            <MenuItem key={index} onClick={handleItemClick(item)} disabled={item.disabled} {...item.props}>
              {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
              {item.tooltip ? (
                <Tooltip title={item.tooltip} arrow>
                  <ListItemText slotProps={{ primary: item.textProps }}>{item.label}</ListItemText>
                </Tooltip>
              ) : (
                <ListItemText slotProps={{ primary: item.textProps }}>{item.label}</ListItemText>
              )}
              {item.subMenuItems && <ChevronRightIcon color="action" sx={{ ml: 1 }} />}
            </MenuItem>
          )
        )}
      </Menu>

      {items.map((item, index) => {
        const { subMenuItems } = item;
        if (!subMenuItems?.length) {
          return null;
        }

        return (
          <Menu
            key={index}
            open={Boolean(subMenuItemAnchor)}
            onClose={() => {
              setSubMenuItemAnchor(null);
              setMenuAnchor(null);
            }}
            anchorEl={subMenuItemAnchor}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
            slotProps={menuSlotProps}
            closeAfterTransition
            disableRestoreFocus
          >
            {subMenuItems.map((item, index) =>
              item.component ? (
                item.component
              ) : (
                <MenuItem key={index} onClick={handleSubItemClick(item)} disabled={item.disabled} {...item.props}>
                  {item.icon && <ListItemIcon>{item.icon}</ListItemIcon>}
                  <ListItemText slotProps={{ primary: item.textProps }}>{item.label}</ListItemText>
                </MenuItem>
              )
            )}
          </Menu>
        );
      })}
    </>
  );
};

export default ActionsMenuButton;
