import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Stack, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import adminApi from "../../../api/adminApi";
import { ObjectAccessCategory, ObjectClassDefinition } from "../../../api/types/objectTypes";
import AccessEditor, { AccessValue } from "../../common/AccessEditor";
import MultiselectAutocomplete from "../../common/MultiselectAutocomplete";
import AddContactDialog from "../../pages/fund-structure/common/AddContactDialog";
import {
  getCategoryOptions,
  mapObjectContactToUserAccess,
  mapUserAccessToObjectContactRequest,
  User,
  UserAccess,
} from "./userAccessMatrixModel";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  categories: ObjectAccessCategory[];
  currentAccessItems: UserAccess[];
  allUsers: User[];
  onSave: (updatedAccessItems: UserAccess[]) => void;
  onClose: () => void;
  onUserCreated: (newUser: User) => void;
}

const createObjectContact = withErrorHandling(adminApi.createObjectContact);
const updateObjectContacts = withErrorHandling(adminApi.updateObjectContacts);

const AddContactAccessDialog = ({
  objectId,
  objectDefinition,
  categories,
  currentAccessItems,
  allUsers,
  onSave,
  onClose,
  onUserCreated,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [isCreatingContact, setCreatingContact] = useState(false);
  const [isCreateContactDialogOpen, setCreateContactDialogOpen] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const [accessValue, setAccessValue] = useState<AccessValue>({
    selectedRole: objectDefinition.supportedContactRoles[0] ?? "None",
    selectedCategoryIds: categories.map((c) => c.id),
  });

  const userOptions = useMemo(() => {
    const currentIds = currentAccessItems.map((c) => c.id);
    return allUsers.filter((c) => !currentIds.includes(c.id)).sort(stringComparerBy((o) => o.name));
  }, [allUsers, currentAccessItems]);

  const { existingContactNames, existingContactEmails } = useMemo(() => {
    const existingContactNames = allUsers.map((c) => c.name);
    const existingContactEmails = allUsers.map((c) => c.email.toLowerCase());
    return { existingContactNames, existingContactEmails };
  }, [allUsers]);

  const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories]);

  const handleContactAdd = async (name: string, email: string) => {
    setCreatingContact(true);
    const [contact, error] = await createObjectContact(objectDefinition.objectType, { name, email });
    setCreatingContact(false);

    if (error) {
      logError(error, "[AddContactAccessDialog] createObjectContact");
      sendNotificationError("Could not create contact");
      return;
    }

    const newUser = { id: contact.id, name: contact.name, email: contact.email };
    onUserCreated(newUser);
    setSelectedUsers((prev) => [...prev, newUser]);
  };

  const handleSave = async () => {
    const addedAccessItems: UserAccess[] = selectedUsers.map((c) => ({
      id: c.id,
      role: accessValue.selectedRole === "None" ? undefined : accessValue.selectedRole,
      categories: accessValue.selectedCategoryIds,
    }));

    const payload = {
      contacts: [...currentAccessItems, ...addedAccessItems].map(mapUserAccessToObjectContactRequest),
    };

    setSaving(true);
    const [updatedContacts, error] = await updateObjectContacts(objectDefinition.objectType, objectId, payload);
    setSaving(false);

    if (error) {
      logError(error, "[AddContactAccessDialog] updateObjectContacts");
      sendNotificationError("Could not add contact(s)");
      return;
    }

    sendNotification("Contact(s) successfully added to this company");
    onSave(updatedContacts.map(mapObjectContactToUserAccess));
    onClose();
  };

  const isValid = selectedUsers.length > 0;

  return (
    <>
      <Dialog open onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Add New</DialogTitle>
        <DialogCloseButton onClick={onClose} />
        <Divider />

        <DialogContent>
          <Stack spacing={4}>
            <Stack spacing={2}>
              <Typography color="text.secondary">
                Select one or more existing contacts or create multiple new ones.
              </Typography>
              <MultiselectAutocomplete
                options={userOptions}
                values={selectedUsers}
                disabled={isCreatingContact}
                onSelected={(items) => setSelectedUsers(items)}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                getOptionDescription={(option) => option.email}
                placeholder="Contacts"
                noItemsText="No contacts found"
                onCreateOption={() => setCreateContactDialogOpen(true)}
              />
            </Stack>

            <AccessEditor
              value={accessValue}
              onChange={(update) => setAccessValue((prev) => ({ ...prev, ...update }))}
              roleOptions={["None", ...objectDefinition.supportedContactRoles]}
              categoryOptions={categoryOptions}
            />
          </Stack>
        </DialogContent>

        <Divider />
        <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" loading={isSaving} onClick={handleSave} disabled={!isValid || isCreatingContact}>
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {isCreateContactDialogOpen && (
        <AddContactDialog
          mode="add"
          onClose={() => setCreateContactDialogOpen(false)}
          onAdd={handleContactAdd}
          existingContactNames={existingContactNames}
          existingContactEmails={existingContactEmails}
        />
      )}
    </>
  );
};

export default AddContactAccessDialog;
