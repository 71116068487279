import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../../../shared/icons/BinIcon";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { Field, ObjectClassDefinition } from "../../../../../api/types/objectTypes";

const deleteField = withErrorHandling(adminApi.deleteObjectField);

interface Props {
  objectType: string;
  deletedField: Field | undefined;
  open: boolean;
  onClose: () => void;
  onFieldDeleted: (fieldId: string, updatedObject: ObjectClassDefinition) => void;
}

const DeleteFieldDialog = ({ objectType, deletedField, open, onClose, onFieldDeleted }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);

  if (!deletedField) {
    return null;
  }

  const handleSubmit = async () => {
    setLoading(true);
    const [updatedObject, error] = await deleteField(objectType, deletedField.id);
    setLoading(false);

    if (error) {
      logError(error, "[DeleteFieldDialog]");
      sendNotificationError("Could not delete data import");
      return;
    }

    sendNotification("Field deleted successfully");
    onFieldDeleted(deletedField.id, updatedObject);
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Delete</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6">Delete field?</Typography>
            <Typography color="text.secondary" textAlign="center">
              You&apos;re about to delete <strong>{deletedField.name}</strong>. This action is permanent and can&apos;t
              be undone.
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" loading={loading} onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteFieldDialog;
