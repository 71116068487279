import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import adminApi from "../../../../../api/adminApi";
import { Fundraising } from "../../../../../api/types/fundraisingTypes";
import FundraisingStatusUpdateIcon from "../FundraisingStatusUpdateIcon";
import { FundraisingStatusTransition } from "../fundraisingsPageTypes";

interface Props {
  onClose: () => void;
  onUpdated: (fundraising: Fundraising) => void;
  fundraisingId: string;
  fundId?: string;
  areNotificationsSetUp?: boolean;
  statusTransition: FundraisingStatusTransition;
}

const updateFundraising = withErrorHandling(adminApi.updateFundraising);

const ChangeFundraisingStatusDialog = ({
  onClose,
  onUpdated,
  fundraisingId,
  fundId,
  areNotificationsSetUp,
  statusTransition,
}: Props) => {
  const getFundraisingAccess = useCallback(
    () =>
      statusTransition.notificationsEnabled && fundId
        ? adminApi.getFundraisingAccess(fundId)
        : Promise.resolve(createApiResponse([])),
    [fundId, statusTransition.notificationsEnabled]
  );

  const [access, error, { isFetching }] = useFetch(getFundraisingAccess);

  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);
  const [notifyUsers, setNotifyUsers] = useState(false);

  const handleSubmit = async () => {
    setSaving(true);
    const [fundraising, error] = await updateFundraising(fundraisingId, {
      status: statusTransition.to,
      sendEmailNotifications: notifyUsers,
    });
    setSaving(false);

    if (error) {
      sendNotificationError("Could not update status");
    } else {
      sendNotification("Status updated successfully");
      onUpdated(fundraising);
    }
  };

  if (error) {
    logError(error, "[ChangeFundraisingStatusDialog] getFundraisingAccess");
  }

  const contactCount = access?.filter((a) => !!a.contactEmail).length ?? 0;

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>{statusTransition.label}</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        {isFetching ? (
          <InlineLoader />
        ) : (
          <Stack alignItems="center" spacing={2}>
            <FundraisingStatusUpdateIcon status={statusTransition.to} />
            <Stack alignItems="center" spacing={1}>
              <Typography noWrap variant="h6">
                {statusTransition.label}
              </Typography>
              {statusTransition.detailLines.map((line, index) => (
                <Typography key={index} color="text.secondary" textAlign="center">
                  {line}
                </Typography>
              ))}
            </Stack>
            {statusTransition.notificationsEnabled && contactCount > 0 && (
              <Box mt={3}>
                <FormControlLabel
                  disabled={!areNotificationsSetUp}
                  control={<Checkbox checked={notifyUsers} onChange={(e) => setNotifyUsers(e.target.checked)} />}
                  label={
                    <Typography color={areNotificationsSetUp ? "text.primary" : "text.disabled"}>
                      Send notification emails to <strong>{`${contactCount} contacts`}</strong>
                    </Typography>
                  }
                />
              </Box>
            )}
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={saving} onClick={handleSubmit} disabled={isFetching}>
          {`${statusTransition.dialogActionLabel}${notifyUsers ? " & Notify" : ""}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeFundraisingStatusDialog;
