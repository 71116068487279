import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import ListWithLazyRender from "../../../../../shared/components/ListWithLazyRender";
import { ObjectFieldValues } from "../../../../api/types/objectTypes";

interface Props {
  companyOptions: ObjectFieldValues[];
  onClose: () => void;
  onAdd: (companyIds: string[]) => void;
}

const AddCompaniesDialog = ({ companyOptions, onClose, onAdd }: Props) => {
  const [selectedCompanyIds, setSelectedCompanyIds] = useState<string[]>([]);

  const handleAdd = () => {
    onAdd(selectedCompanyIds);
    onClose();
  };

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>Add Companies</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Autocomplete
          multiple
          fullWidth
          options={companyOptions}
          getOptionLabel={(o) => o.name}
          onChange={(_, items) => items && setSelectedCompanyIds(items.map((i) => i.id))}
          renderInput={(inputProps) => <TextField {...inputProps} autoFocus placeholder="Type company name..." />}
          renderOption={(props, option) => (
            <ListItem {...props} key={option.id}>
              <Typography>{option.name}</Typography>
            </ListItem>
          )}
          slotProps={{ listbox: { component: ListWithLazyRender } }}
        />
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleAdd} disabled={selectedCompanyIds.length === 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCompaniesDialog;
