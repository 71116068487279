import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { FileTag } from "../../../../api/types/fileTypes";
import { getEntityTitle } from "../../../common/files/fileDetailsHelper";
import TagEditForm from "./TagEditForm";
import { TagFormState, TagSettingsDialogState } from "./dialogState";

interface Props {
  dialogState: TagSettingsDialogState;
  onFormChange: (form: TagFormState) => void;
  onCancel: () => void;
  onSubmit: (catalogueId: string, updatedTag: FileTag) => void;
}

const updateFileTag = withErrorHandling(adminApi.updateFileTag);

const EditTagDialog = ({ dialogState, onFormChange, onCancel, onSubmit }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    if (!dialogState.form.isValid) {
      return;
    }

    const { catalogueId } = dialogState.form;
    if (!catalogueId || !dialogState.form.entityType) {
      return;
    }

    setSaving(true);
    const [resp, error] = await updateFileTag(catalogueId, { name: dialogState.form.name.trim() });
    if (error) {
      logError(error, "updateFileTag");
      sendNotificationError("Could not update file tag");
    } else {
      sendNotification(
        `Tag "${dialogState.form.name}" successfully updated for entity ${getEntityTitle(dialogState.form.entityType)}`
      );
      onSubmit(catalogueId, resp);
    }
    setSaving(false);
  };

  return (
    <Dialog open={dialogState.openDialog === "edit"} onClose={onCancel} sx={{ ".MuiPaper-root": { width: "32rem" } }}>
      <DialogTitle>Edit Tag</DialogTitle>
      <DialogCloseButton onClick={onCancel} />
      <DialogContent sx={(t) => ({ px: t.spacing(4) })}>
        <TagEditForm form={dialogState.form} onChange={onFormChange} disableEntitySelection />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          disabled={!dialogState.form.isValid}
          variant="contained"
          color="primary"
          loading={saving}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditTagDialog;
