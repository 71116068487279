import DeleteIcon from "@mui/icons-material/DeleteOutlineRounded";
import SaveIcon from "@mui/icons-material/SaveAltRounded";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import FileIcon from "../../../../../shared/components/FileIcon";
import FilesDropArea from "../../../../../shared/components/FilesDropArea";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { downloadFileFromUrl } from "../../../../../shared/services/downloadFile";
import { formatFileSize, getFileExtension } from "../../../../../shared/utilities/fileHelper";
import { fileToUploadValidator, ValidationResult } from "../../../../../shared/utilities/validators";
import { ImportSubmissionResponse, SubmissionImportService } from "../../../../api/dataCollectionTypes";

interface Props<TSubmission> {
  submissionImportService: SubmissionImportService<TSubmission>;
  submissionId: string;
  blockId: string;
  onClose: () => void;
  onImport: (importResp: ImportSubmissionResponse<TSubmission>) => void;
}

const acceptedFileExtensions = [".xlsx", ".xls"];
const dropAreaSubtitle = "Supported formats: .xlsx, .xls";
const maxFileSize = 10 * 1024 * 1024;
const fileValidator = fileToUploadValidator({ maxFileSize, acceptedFileExtensions });

interface FileState {
  file: File;
  validationResult: ValidationResult;
}

const ImportSubmissionBlockToExcelDialog = <TSubmission,>({
  submissionImportService,
  submissionId,
  blockId,
  onClose,
  onImport,
}: Props<TSubmission>) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [exporting, setExporting] = useState(false);
  const [importing, setImporting] = useState(false);
  const [fileState, setFileState] = useState<FileState>();

  const handleAddFiles = (files: File[]) => {
    const file = files[0];
    if (!file) {
      return;
    }

    const validationResult = fileValidator(file);
    setFileState({ file, validationResult });
  };

  const handleDeleteFile = () => {
    setFileState(undefined);
  };

  const handleExport = async () => {
    const exportToExcel = withErrorHandling(submissionImportService.exportSubmissionBlockInputToExcel);

    setExporting(true);
    const [fileDownloadInfo, error] = await exportToExcel(submissionId, blockId);
    setExporting(false);

    if (error) {
      logError(error, "[ImportSubmissionBlockToExcelDialog] exportSubmissionBlockInputToExcel");
      sendNotificationError("Failed to export submission form");
      return;
    }

    downloadFileFromUrl(fileDownloadInfo.downloadUrl);
  };

  const handleStartImport = async () => {
    if (fileState === undefined) {
      return;
    }

    const importFromExcel = withErrorHandling(submissionImportService.importSubmissionBlockInputFromExcel);

    setImporting(true);
    const [resp, error] = await importFromExcel(submissionId, blockId, fileState.file);
    setImporting(false);

    if (error) {
      logError(error, "[ImportSubmissionBlockToExcelDialog] importSubmissionBlockInputFromExcel");
      sendNotificationError("Failed to import file");
      return;
    }

    if (resp.updatedSubmission === undefined) {
      sendNotificationError("Could not import file data. Please check the errors and try again.");
    } else if (resp.errors.length > 0) {
      sendNotification("Import has been partially applied. Please check the errors displayed on the page.");
    } else {
      sendNotification("Import applied successfully");
    }

    onImport(resp);
  };

  const isStartImportDisabled = !fileState || !fileState.validationResult.isValid;

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "37.5rem" } } }}>
      <DialogTitle>Import Data</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <Alert severity="info" title="Metric Extensions">
            Please add metric extensions (e.g., products, regions, etc.) first and “Save” the form before exporting to
            Excel. New extensions added directly in Excel won&apos;t be recognized during import.
          </Alert>

          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography variant="subtitle2">Import File</Typography>
            <Button variant="text" loading={exporting} startIcon={<SaveIcon />} onClick={handleExport}>
              Export Submission Form
            </Button>
          </Box>

          {fileState && (
            <Box pb={20}>
              <TableContainer component={Paper} variant="outlined" sx={{ width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
                      <TableCell sx={{ width: 120 }}>Size</TableCell>
                      <TableCell sx={{ width: 64 }}></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <FileIcon fileExtension={getFileExtension(fileState.file.name)} />
                          <Typography width="21rem" noWrap>
                            {fileState.file.name}
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell>
                        <Typography noWrap>{formatFileSize(fileState.file.size)}</Typography>
                      </TableCell>
                      <TableCell>
                        <IconButton color="error" onClick={handleDeleteFile}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )}

          {!fileState && (
            <Box>
              <FilesDropArea
                acceptedFileExtensions={acceptedFileExtensions}
                subtitle={dropAreaSubtitle}
                onFilesAdd={handleAddFiles}
                containerProps={{ py: 12 }}
              />
            </Box>
          )}
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={importing} onClick={handleStartImport} disabled={isStartImportDisabled}>
          Start Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ImportSubmissionBlockToExcelDialog;
