import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { getErrorMessage } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DwhShare } from "../../../api/types/dwhTypes";
import DwhShareDeletedIcon from "../../../icons/DwhShareDeletedIcon";

interface Props {
  share: DwhShare | undefined;
  onCancel: () => void;
  onShareDeleted: (accountCode: string) => void;
}

const DeleteShareDialog = ({ share, onCancel, onShareDeleted }: Props) => {
  const [deleting, setDeleting] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCancel = () => {
    onCancel();
    setErrorMessage("");
  };

  const handleDelete = async () => {
    if (!share) {
      return;
    }

    setDeleting(true);
    try {
      await adminApi.deleteDwhShare(share.id);
      onShareDeleted(share.accountCode.toUpperCase());
    } catch (error) {
      const errorMessage = getErrorMessage(error) || "Server error";
      logError(errorMessage, "DeleteDwhShare");
      setErrorMessage(errorMessage);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Dialog open={!!share} onClose={handleCancel} sx={{ ".MuiPaper-root": { maxWidth: "100%" } }}>
      <DialogTitle>Delete Sharing</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        <Stack alignItems="center">
          <DwhShareDeletedIcon sx={{ width: 80, height: 80, my: 2 }} />
          <Typography variant="h6">Are you sure you want to delete the sharing?</Typography>
          <Typography color="text.secondary">{share?.accountCode}</Typography>
          {errorMessage && (
            <Typography color="error" mt={2}>
              {errorMessage}
            </Typography>
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" loading={deleting} onClick={handleDelete}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteShareDialog;
