import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { Badge, Button } from "@mui/material";

interface Props {
  showBadge: boolean;
  loading?: boolean;
  disabled?: boolean;
  title?: string;
  onClick: () => void;
}
export default function ReportTemplatesButton({
  showBadge,
  loading,
  disabled,
  onClick,
  title = "Report Templates",
}: Props) {
  return (
    <Badge color="info" variant="dot" invisible={!showBadge}>
      <Button
        variant="outlined"
        color="secondary"
        disabled={disabled}
        loading={loading}
        startIcon={<DescriptionOutlinedIcon sx={(theme) => ({ color: theme.palette.action.active })} />}
        onClick={onClick}
      >
        {title}
      </Button>
    </Badge>
  );
}
