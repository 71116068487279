import SentIcon from "@mui/icons-material/Check";
import MonitorIcon from "@mui/icons-material/Monitor";
import { Button, Card, CardContent, Divider, Link, Stack, Typography } from "@mui/material";
import qs from "qs";
import { useState } from "react";
import UserAvatar from "../../../../../../../shared/components/UserAvatar";
import CopyToBufferButton from "../../../../../../../shared/components/inputs/CopyToBufferButton";
import { wait } from "../../../../../../../shared/utilities/promiseHelper";
import adminApi from "../../../../../../api/adminApi";
import { MessageDeliveryRecipient } from "../../../../../../api/types/documentCollectionTypes";
import { useClientContext } from "../../../../../../context/ClientContext";
import { getVariables } from "../../../../../../variables";
import { useDocumentCollectionContext } from "../../DocumentCollectionContext";
import ContactDeliveryStatus from "./ContactDeliveryStatus";

interface Props {
  recipient: MessageDeliveryRecipient;
  messageRequestId: string;
}

type SendInviteState = "ready" | "sending" | "sent";

const RecipientDetail = ({ text }: { text?: string }) => {
  if (!text) {
    return null;
  }

  return (
    <Typography
      color="text.secondary"
      fontSize="0.75 rem"
      sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
    >
      {text}
    </Typography>
  );
};

const cooldownDelayMs = 3000;

const buttonTexts: Record<SendInviteState, string> = {
  ready: "Resend invite",
  sent: "Sent",
  sending: "Sending...",
};

const ContactDeliveryDetails = ({ recipient, messageRequestId }: Props) => {
  const { clientCode } = useClientContext();
  const [sendState, setSendState] = useState<SendInviteState>("ready");
  const { setSentInvites } = useDocumentCollectionContext();

  const handleButtonClick = async () => {
    if (sendState !== "ready" || !recipient.referenceId) {
      return;
    }

    try {
      setSendState("sending");

      const resp = await adminApi.resendInvitationEmailByMessage({
        messageRequestId,
        userId: recipient.referenceId,
      });

      if (resp.success) {
        setSendState("sent");

        const { referenceId } = recipient;
        if (referenceId) {
          setSentInvites((current) => ({ ...current, [referenceId]: new Date() }));
        }

        await wait(cooldownDelayMs);
      }
    } finally {
      setSendState("ready");
    }
  };

  const impersonateUrl = `${getVariables().IR_PORTAL_URI}/${clientCode}/inbox?${qs.stringify({
    impersonation: true,
    user: recipient.email,
  })}`;

  return (
    <Card sx={{ width: "19rem" }}>
      <CardContent>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center" spacing={1.5}>
            <UserAvatar size="large" userName={recipient.name} />
            <Stack spacing={0.5}>
              <Typography variant="subtitle2">{recipient.name}</Typography>
              <Stack direction="row" spacing={0.5}>
                <RecipientDetail text={recipient.email} />
                {recipient.email && <CopyToBufferButton textToCopy={recipient.email} />}
              </Stack>
              <RecipientDetail text={recipient.phone} />
            </Stack>
          </Stack>
          <Divider />
          <Stack spacing={1.5}>
            <ContactDeliveryStatus recipient={recipient} />
            <Stack direction="row" spacing={1}>
              {recipient.referenceId && !recipient.isRegistered && (
                <Button
                  variant="outlined"
                  color="primary"
                  loading={sendState === "sending"}
                  fullWidth
                  startIcon={sendState === "sent" ? <SentIcon /> : undefined}
                  disabled={sendState === "sending"}
                  onClick={handleButtonClick}
                  sx={{ cursor: sendState === "ready" ? "pointer" : "default" }}
                >
                  {buttonTexts[sendState]}
                </Button>
              )}
              {recipient.email && (
                <Button
                  component={Link}
                  href={impersonateUrl}
                  variant="outlined"
                  color="secondary"
                  fullWidth
                  startIcon={<MonitorIcon />}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Impersonate
                </Button>
              )}
            </Stack>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default ContactDeliveryDetails;
