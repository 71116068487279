import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataModelConfiguration } from "../../../../api/types/dataModelConfigurationTypes";
import { useConfigurationsGridActionsContext } from "./ConfigurationsGridActionsContext";
import { getInitialEditConfigurationState, isFormDirty, updateNameAction } from "./editConfigurationState";

interface Props {
  editedConfiguration: DataModelConfiguration;
  onClose: () => void;
  onUpdated: (updatedConfiguration: DataModelConfiguration) => void;
}

const updateConfiguration = withErrorHandling(adminApi.updateDataModelConfiguration);

const EditConfigurationDialog = ({ editedConfiguration, onClose, onUpdated }: Props) => {
  const { configurations } = useConfigurationsGridActionsContext();
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [loading, setLoading] = useState(false);
  const [formState, setFormState] = useState(getInitialEditConfigurationState(configurations, editedConfiguration));

  const handleSaveClick = async () => {
    setLoading(true);
    const [updatedConfiguration, error] = await updateConfiguration(editedConfiguration.id, {
      name: formState.name.trim(),
    });
    setLoading(false);

    if (error) {
      logError(error, "[EditConfigurationDialog] handleSaveClick");
      sendNotificationError("Failed to save the configuration");
      return;
    }

    sendNotification("Configuration saved successfully");
    onUpdated(updatedConfiguration);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Rename Configuration</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <DialogContent>
        <TextField
          label="Name"
          value={formState.name}
          onChange={(e) => setFormState(updateNameAction(e.target.value))}
          error={!formState.validationResult.isValid}
          helperText={formState.validationResult.error}
          fullWidth
          autoFocus
        />
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          loading={loading}
          onClick={handleSaveClick}
          disabled={!formState.validationResult.isValid || !isFormDirty(formState)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditConfigurationDialog;
