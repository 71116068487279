import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import { MetricDataType } from "../../../../../shared/api/portfolioMonitoringTypes";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import { distinct } from "../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../api/adminApi";
import { Metric } from "../../../../api/types/portfolioMonitoringTypes";
import { useMetricsPageContext } from "../MetricsPageContext";
import MetricEditor from "./MetricEditor";
import {
  getInitialMetricEditorFormState,
  mapFormToCreateMetricRequest,
  MetricEditorFormState,
} from "./metricEditorHelper";

interface Props {
  dataType: MetricDataType;
  onClose: () => void;
  onCreated: (metric: Metric) => void;
}

const createMetric = withErrorHandling(adminApi.createMetric);

const AddMetricDialog = ({ dataType, onClose, onCreated }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { metrics } = useMetricsPageContext();

  const [saving, setSaving] = useState(false);

  const [formState, setFormState] = useState<MetricEditorFormState>(getInitialMetricEditorFormState(dataType));

  const handleCreateMetric = async () => {
    if (!formState.isFormValid) {
      return;
    }

    setSaving(true);
    const [newMetric, error] = await createMetric(mapFormToCreateMetricRequest(formState));
    setSaving(false);

    if (error) {
      sendNotificationError("Could not add metric");
      logError(error, "[CreateMetricDialog] createMetric");
    } else {
      sendNotification("Metric added successfully");
      onCreated(newMetric);
    }
  };

  const otherMetricNames = metrics.map((m) => m.name);
  const allMetricCategories = distinct(metrics.map((m) => m.category).filter(Boolean)) as string[];

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>New Metric</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <MetricEditor
          form={formState}
          onChange={setFormState}
          otherMetricNames={otherMetricNames}
          allMetricCategories={allMetricCategories}
        />
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!formState.isFormValid} variant="contained" loading={saving} onClick={handleCreateMetric}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMetricDialog;
