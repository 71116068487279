import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import BorderedRadioLabel from "../../../common/BorderedRadioLabel";

interface ConsentUpdateVariantDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (updateVersion: boolean) => void;
}

type Option = "new" | "all";

const OptionLabel = ({ title, description }: { title: string; description: React.ReactNode }) => (
  <Stack spacing={0.5}>
    <Typography variant="subtitle2">{title}</Typography>
    <Typography>{description}</Typography>
  </Stack>
);

const UpdateConsentVersionDialog = ({ open, onClose, onConfirm }: ConsentUpdateVariantDialogProps) => {
  const [selectedOption, setSelectedOption] = useState<Option>("new");

  return (
    <Dialog open={open} onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>Version Update</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <Stack spacing={0.5}>
            <Typography color="text.secondary">Select who has to agree with the updated consent version.</Typography>
          </Stack>

          <RadioGroup
            defaultValue="new"
            value={selectedOption}
            sx={{ rowGap: 1 }}
            onChange={(e) => setSelectedOption(e.target.value as Option)}
          >
            <BorderedRadioLabel<Option>
              value="new"
              selectedValue={selectedOption}
              label={
                <OptionLabel
                  title="New users"
                  description="Only newly registered portal users will agree to the updated version."
                />
              }
              sx={{ pl: 1.5, py: 1.5 }}
            />
            <BorderedRadioLabel<Option>
              value="all"
              selectedValue={selectedOption}
              label={
                <OptionLabel
                  title="All Users"
                  description={
                    <>
                      All portal users have to agree with the updated version.
                      <br /> The prompt will be displayed on their next activity on the portal.
                    </>
                  }
                />
              }
              sx={{ pl: 1.5, py: 1.5 }}
            />
          </RadioGroup>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ px: 3, py: 2, columnGap: 1 }}>
        <Button onClick={onClose} variant="text" color="secondary">
          Cancel
        </Button>
        <Button onClick={() => onConfirm(selectedOption === "all")} variant="contained">
          Update and Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateConsentVersionDialog;
