import MailIcon from "@mui/icons-material/MailOutlineRounded";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { getErrorMessage, withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../shared/logging";
import {
  combineValidators,
  emailValidator,
  maxCharactersValidator,
  requiredValidator,
} from "../../../../../../shared/utilities/validators";
import adminApi, { Contact } from "../../../../../api/adminApi";

interface Props {
  contact: Contact;
  onClose: () => void;
  onSaved: (email: string, name: string) => void;
}

const changeContactEmailAndFundPortalLogin = withErrorHandling(adminApi.changeContactEmailAndFundPortalLogin);

const maxNameLength = 100;
const validateEmail = combineValidators(requiredValidator, emailValidator);
const validateName = combineValidators(requiredValidator, maxCharactersValidator(100));

const validateForm = (email: string, name: string) => {
  const emailResult = validateEmail(email);
  const nameResult = validateName(name);

  return {
    isValid: emailResult.isValid && nameResult.isValid,
    emailResult,
    nameResult,
  };
};

const ContactEmailChangeDialog = ({ contact, onClose, onSaved }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [email, setEmail] = useState(contact.email);
  const [name, setName] = useState(contact.name);
  const [sendInvitation, setSendInvitation] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleSave = async () => {
    setLoading(true);

    const newName = name.trim() !== contact.name.trim() ? name : undefined;
    const [resp, error] = await changeContactEmailAndFundPortalLogin(contact.id, {
      newEmail: email,
      newName,
      sendInvitation,
    });

    setLoading(false);

    if (error) {
      logError(error, "[ContactEmailChangeDialog] changeContactEmailAndFundPortalLogin");
      sendNotificationError(getErrorMessage(error));
      return;
    }

    const notificationMessage = resp.invitationSent
      ? "Changes saved and invitation email sent successfully"
      : "Changes saved successfully";

    sendNotification(notificationMessage);
    onClose();
    onSaved?.(email, name);
  };

  const validationResults = validateForm(email, name);

  const emailChanged = email.trim().toLowerCase() !== contact.email.trim().toLowerCase();

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>Replace</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2} useFlexGap>
          <Alert severity="warning" sx={{ mb: 1 }}>
            Changing the email will also change the login information on{" "}
            {contact.hasAccessToInvestorPortal && contact.hasAccessToPortfolioPortal
              ? "the Investor Portal and the Portfolio Portal"
              : contact.hasAccessToInvestorPortal
                ? "the Investor Portal"
                : contact.hasAccessToPortfolioPortal
                  ? "the Portfolio Portal"
                  : "any connected portals"}
            . This will require the contact to re-register with the new email.
          </Alert>

          <TextField
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            label="Email"
            error={!validationResults.emailResult.isValid}
            helperText={validationResults.emailResult.error}
            slotProps={{
              input: { startAdornment: <MailIcon color="action" sx={{ mr: 1 }} /> },
            }}
          />

          <TextField
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            label="Name"
            error={!validationResults.nameResult.isValid}
            helperText={validationResults.nameResult.error}
            slotProps={{
              htmlInput: { maxLength: maxNameLength },
              input: {
                endAdornment: (
                  <Typography color="text.secondary" variant="caption">
                    {maxNameLength - name.length}
                  </Typography>
                ),
              },
            }}
          />

          <FormControlLabel
            label="Send invitation email"
            control={<Checkbox checked={sendInvitation} onChange={(_, checked) => setSendInvitation(checked)} />}
            disabled={!contact.hasAccessToInvestorPortal}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>

        <Button
          variant="contained"
          loading={loading}
          onClick={handleSave}
          disabled={!emailChanged || !validationResults.isValid}
        >
          {sendInvitation ? "Save & Invite" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ContactEmailChangeDialog;
