import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import { ReportInfo } from "../../../../../../shared/reporting/api/biClient.types";
import { useLocalization } from "../../../../../hooks/useLocalization";
import CancelReportsCopyingIcon from "../../../../../icons/CancelReportsCopyingIcon";

interface Props {
  reports: ReportInfo[];
  onOkay: () => void;
  onCancel: () => void;
}

export default function CancelPromptDialog({ reports, onOkay, onCancel }: Props) {
  const { reports_copyto: locale } = useLocalization();

  return (
    <Dialog
      open={true}
      hideBackdrop
      slotProps={{ paper: { sx: { width: "500px", borderRadius: "8px" } } }}
      onClose={onCancel}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ px: 3, py: 2 }}>
        <Typography variant="h6" fontFamily="Roboto">
          Stop Copying
        </Typography>
        <CloseIconButton onClick={onCancel} />
      </Stack>
      <DialogContent>
        <Stack sx={{ gap: 1.5, px: 2, alignItems: "center", textAlign: "center" }}>
          <CancelReportsCopyingIcon />
          <Typography variant="h6" fontFamily="Roboto">
            Stop copying report{reports.length > 1 ? "s" : ""}?
          </Typography>
          <Typography color="text.secondary">{locale.cancel_copying_report}</Typography>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" onClick={onOkay}>
          Stop Copying
        </Button>
      </DialogActions>
    </Dialog>
  );
}
