import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider } from "@mui/material";
import { useCallback, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../shared/hooks/useFetch";
import { logError } from "../../../../shared/logging";
import { stringComparerBy } from "../../../../shared/utilities/arrayHelper";
import { defined } from "../../../../shared/utilities/typeHelper";
import adminApi, { ApiGatewaySubscription } from "../../../api/adminApi";
import ApiGatewaySubscriptionForm, { SubscriptionFormState } from "./ApiGatewaySubscriptionForm";

interface Props {
  open: boolean;
  onCancel: () => void;
  onSubmit: (newSubscription: ApiGatewaySubscription) => void;
}

const addApiGatewaySubscription = withErrorHandling(adminApi.addApiGatewaySubscription);

const getInitialForm = (): SubscriptionFormState => ({ user: null, description: "", type: "EntriliaApi" });

const AddSubscriptionDialog = ({ open, onCancel, onSubmit }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const fetchUsers = useCallback(
    async () => (open ? adminApi.getApiGatewayEligibleUsers() : createApiResponse([])),
    [open]
  );

  const [users, error] = useFetch(fetchUsers);

  if (error) {
    logError(error.message, "AddSubscriptionDialog");
  }

  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<SubscriptionFormState>(getInitialForm());

  const isFormValid = () => Boolean(form.user);

  const handleFormChange = (value: SubscriptionFormState) => setForm(value);

  const handleCancel = () => {
    onCancel();
    setForm(getInitialForm());
  };

  const handleSubmit = async () => {
    if (!isFormValid()) {
      return;
    }

    const selectedUser = defined(form.user);

    setSaving(true);
    const [newSubscription, error] = await addApiGatewaySubscription({
      userId: selectedUser.userId,
      description: form.description || undefined,
      state: "Active",
      type: form.type,
    });
    setSaving(false);

    if (error) {
      logError(error, "[AddApiGatewaySubscriptionDialog]");
      sendNotificationError(`Failed to add subscription for ${selectedUser.username}`);
      return;
    }

    sendNotification(`Subscription for ${selectedUser.username} successfully added`);
    onSubmit(newSubscription);
    setTimeout(() => setForm(getInitialForm()));
  };

  const sortedUsers = users ? users.sort(stringComparerBy((user) => user.username)) : undefined;

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs" fullWidth>
      <DialogTitle>Add Subscription</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <Divider />
      <DialogContent sx={(t) => ({ px: t.spacing(4) })}>
        <ApiGatewaySubscriptionForm users={sortedUsers} form={form} onChange={handleFormChange} />
      </DialogContent>
      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" loading={saving} onClick={handleSubmit} disabled={!isFormValid()}>
          Add Subscription
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubscriptionDialog;
