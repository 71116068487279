import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid2,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { combineComparers, priorityComparer, stringComparerBy } from "../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../api/adminApi";
import MultiselectAutocomplete from "../../../common/MultiselectAutocomplete";
import WatermarkPreview from "./WatermarkPreview";
import { watermarkSegmentCaptionMap } from "./watermarkSettingsHelper";
import {
  getWatermarkSettingsStateFromSavedSettings,
  mapWatermarkSettingsStateToUpdateRequest,
  validateWatermarkSettingsState,
  WatermarkCategoriesOption,
  WatermarkItemKey,
  WatermarkSettingsState,
} from "./watermarkSettingsPageState";

const updateWatermarkSettings = withErrorHandling(adminApi.updateWatermarkSettings);

interface CategoryOption {
  externalId: string;
  name: string;
  group: string;
}

const WatermarkSettingsPage = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [settingsState, setSettingsState] = useState<WatermarkSettingsState>();
  const [loading, setLoading] = useState(false);

  const [savedSettings, settingsError, { setData: setSavedSettings }] = useFetch(
    adminApi.getWatermarkSettings,
    (data) => {
      setSettingsState(getWatermarkSettingsStateFromSavedSettings(data));
    }
  );

  const [categories, categoriesError] = useFetch(adminApi.getAccessCategories);

  const categoryOptions = useMemo<CategoryOption[]>(() => {
    const sortComparer = combineComparers<CategoryOption>(
      priorityComparer((c) => c.group === "Investor Portal"),
      stringComparerBy((c) => c.name)
    );

    return (categories ?? [])
      .filter((c) => ["Message", "Fundraising"].includes(c.type))
      .map((c) => ({
        externalId: c.externalId,
        name: c.name,
        group: c.type === "Message" ? "Investor Portal" : "Fundraising",
      }))
      .sort(sortComparer);
  }, [categories]);

  const fetchError = settingsError || categoriesError;
  if (fetchError) {
    logError(fetchError, "WatermarkSettingsPage");
    return <DataLoadingFailed title="Could not load watermark settings" sx={{ height: "100%" }} />;
  }

  if (!settingsState || !savedSettings || !categories) {
    return <InlineLoader />;
  }

  const handleWatermarkEnabledToggle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsState((prev) => (prev ? { ...prev, isDirty: true, watermarkEnabled: event.target.checked } : prev));
  };

  const handleCategoriesOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsState((prev) =>
      prev
        ? {
            ...prev,
            isDirty: true,
            categoriesOption: event.target.value as WatermarkCategoriesOption,
          }
        : prev
    );
  };

  const handleSelectedCategoriesChange = (newCategories: CategoryOption[]) => {
    setSettingsState((prev) =>
      prev
        ? {
            ...prev,
            isDirty: true,
            externalCategoryIds: newCategories.map((category) => category.externalId),
          }
        : prev
    );
  };

  const handleWatermarkItemToggle =
    (itemKey: WatermarkItemKey) => (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setSettingsState((prev) =>
        prev
          ? {
              ...prev,
              isDirty: true,
              items: {
                ...prev.items,
                [itemKey]: { ...prev.items[itemKey], visibility: checked },
              },
            }
          : prev
      );
    };

  const handleCustomTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettingsState((prev) =>
      prev
        ? {
            ...prev,
            isDirty: true,
            customTextValue: event.target.value,
            items: {
              ...prev.items,
              customText: { ...prev.items.customText, value: event.target.value },
            },
          }
        : prev
    );
  };

  const handleSave = async () => {
    setLoading(true);

    const data = mapWatermarkSettingsStateToUpdateRequest(settingsState);

    const [updatedSettings, error] = await updateWatermarkSettings(data);

    setLoading(false);

    if (error) {
      sendNotificationError("Failed to save watermark settings");
      logError(error, "[WatermarkSettingsPage] updateWatermarkSettings");
      return;
    }

    sendNotification("Watermark settings saved successfully");
    setSettingsState(getWatermarkSettingsStateFromSavedSettings(updatedSettings));
    setSavedSettings(updatedSettings);
  };

  const handleReset = () => {
    setSettingsState(getWatermarkSettingsStateFromSavedSettings(savedSettings));
  };

  const validationResult = validateWatermarkSettingsState(settingsState);

  const selectedCategories = categoryOptions.filter((category) =>
    settingsState.externalCategoryIds.includes(category.externalId)
  );

  return (
    <Grid2 container height="100%">
      <Grid2 size={3} py={2.5} px={3} gap={3} display="flex" flexDirection="column">
        <Stack spacing={2}>
          <Stack spacing={1}>
            <Typography variant="subtitle1">Watermark Settings</Typography>
            <Typography color="secondary">
              The watermark will be applied both when viewing and downloading PDF files.
            </Typography>
          </Stack>
          <FormControlLabel
            control={<Switch checked={!!settingsState.watermarkEnabled} onChange={handleWatermarkEnabledToggle} />}
            label="Watermark PDF files"
          />
        </Stack>

        <Divider />

        <Stack spacing={0.5}>
          <Typography variant="subtitle1">Document Categories</Typography>
          <RadioGroup
            defaultValue="all_categories"
            value={settingsState.categoriesOption}
            onChange={handleCategoriesOptionChange}
          >
            <FormControlLabel
              control={<Radio />}
              disabled={!settingsState.watermarkEnabled}
              value="all_categories"
              label="Apply to all categories"
            />
            <FormControlLabel
              control={<Radio />}
              disabled={!settingsState.watermarkEnabled}
              value="selected_categories"
              label="Apply to selected categories only"
            />
          </RadioGroup>

          {settingsState.categoriesOption === "selected_categories" && (
            <MultiselectAutocomplete
              options={categoryOptions}
              values={selectedCategories}
              disabled={!settingsState.watermarkEnabled}
              onSelected={handleSelectedCategoriesChange}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.externalId}
              groupBy={(option) => option.group}
              placeholder="Add categories"
              optionTextVariant="body1"
              error={Boolean(validationResult.documentCategoriesError)}
              helperText={validationResult.documentCategoriesError}
              dense
            />
          )}
        </Stack>

        <Divider />

        <Stack spacing={0.5}>
          <Typography variant="subtitle1">Details</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.items.customText.visibility}
                onChange={handleWatermarkItemToggle("customText")}
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={watermarkSegmentCaptionMap[settingsState.items.customText.id]}
          />
          {settingsState.items.customText.visibility && (
            <Box pl={1}>
              <TextField
                value={settingsState.items.customText.value}
                onChange={handleCustomTextChange}
                disabled={!settingsState.watermarkEnabled}
                fullWidth
                slotProps={{
                  htmlInput: { maxLength: 50 },
                  input: {
                    endAdornment: (
                      <Typography color="text.secondary" variant="caption">
                        {50 - settingsState.items.customText.value.length}
                      </Typography>
                    ),
                  },
                }}
                error={Boolean(validationResult.customTextError)}
                helperText={validationResult.customTextError}
              />
            </Box>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.items.contactName.visibility}
                onChange={handleWatermarkItemToggle("contactName")}
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={watermarkSegmentCaptionMap[settingsState.items.contactName.id]}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.items.contactEmail.visibility}
                onChange={handleWatermarkItemToggle("contactEmail")}
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={watermarkSegmentCaptionMap[settingsState.items.contactEmail.id]}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.items.investorName.visibility}
                onChange={handleWatermarkItemToggle("investorName")}
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={watermarkSegmentCaptionMap[settingsState.items.investorName.id]}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!settingsState.items.dateAndTime.visibility}
                onChange={handleWatermarkItemToggle("dateAndTime")}
              />
            }
            disabled={!settingsState.watermarkEnabled}
            label={watermarkSegmentCaptionMap[settingsState.items.dateAndTime.id]}
          />
          <FormHelperText error={Boolean(validationResult.enabledItemsError)}>
            {validationResult.enabledItemsError ?? ""}
          </FormHelperText>
        </Stack>

        <Divider />

        <Stack direction="row" spacing={1}>
          <Button
            variant="contained"
            disabled={!settingsState.isDirty || !validationResult.isValid}
            loading={loading}
            onClick={handleSave}
          >
            Save
          </Button>
          <Button variant="text" color="secondary" disabled={!settingsState.isDirty} onClick={handleReset}>
            Cancel
          </Button>
        </Stack>
      </Grid2>

      <Grid2
        display="flex"
        flexDirection="column"
        size={9}
        sx={(t) => ({ borderLeft: "1px solid", borderColor: t.palette.divider })}
      >
        <Stack>
          <Box py={2} px={2.5} sx={(t) => ({ borderBottom: "1px solid", borderColor: t.palette.divider })}>
            <Typography variant="subtitle1">Watermark Preview</Typography>
          </Box>
        </Stack>
        <Stack height="100%" bgcolor={grey[100]} p={2}>
          <WatermarkPreview
            watermarkEnabled={settingsState.watermarkEnabled}
            items={Object.values(settingsState.items)}
          />
        </Stack>
      </Grid2>
    </Grid2>
  );
};

export default WatermarkSettingsPage;
