import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import adminApi, { ApiGatewaySubscription } from "../../../api/adminApi";
import UserDeletedIcon from "../../../icons/UserDeletedIcon";

interface Props {
  open: boolean;
  subscription: ApiGatewaySubscription | undefined;
  onCancel: () => void;
  onSubmit: (subscriptionId: string) => void;
}

const DeleteSubscriptionDialog = ({ open, subscription, onCancel, onSubmit }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [deleting, setDeleting] = useState(false);

  const handleCancel = () => {
    onCancel();
  };

  const handleSubmit = async () => {
    if (!subscription) {
      return;
    }

    setDeleting(true);
    try {
      await adminApi.deleteApiGatewaySubscription(subscription.id);
      sendNotification(`Subscription for ${subscription.username} deleted`);
      onSubmit(subscription.id);
    } catch (error) {
      logError(error, "DeleteApiGatewaySubscriptionDialog");
      sendNotificationError(`Failed to delete subscription for ${subscription.username}`);
    } finally {
      setDeleting(false);
    }
  };

  return (
    <Dialog open={open} onClose={handleCancel} sx={{ ".MuiPaper-root": { width: "32rem" } }}>
      <DialogTitle>Delete Subscription</DialogTitle>
      <DialogCloseButton onClick={handleCancel} />
      <DialogContent sx={(theme) => ({ px: theme.spacing(4) })}>
        {subscription && (
          <Stack alignItems="center">
            <UserDeletedIcon sx={{ width: 80, height: 80, mb: 2 }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Are you sure you want to delete the subscription?
            </Typography>
            <Typography variant="subtitle1">{subscription.username}</Typography>
            <Typography color="text.secondary">{subscription.email}</Typography>
          </Stack>
        )}
      </DialogContent>
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant="outlined" color="error" loading={deleting} onClick={handleSubmit}>
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSubscriptionDialog;
