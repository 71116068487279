import { Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { PassthroughConfiguration, PassthroughEnvironment } from "../../../../api/types/passthroughTypes";
import { PassthroughPageState } from "../passthroughPageState";
import PassthroughConfigurationHeader from "./PassthroughConfigurationHeader";

const regionConfigurationOptions = new Map<PassthroughEnvironment, string>([
  [PassthroughEnvironment.Sandbox, "Sandbox"],
  [PassthroughEnvironment.Us, "United States"],
  [PassthroughEnvironment.Eu, "Europe"],
]);

interface Props {
  state: PassthroughPageState;
  disabled: boolean;
  onConfigurationChange: (data: Partial<PassthroughConfiguration>) => void;
  onConfigurationSave: () => void;
}

const PassthroughConfigurationContainer = ({ state, disabled, onConfigurationChange, onConfigurationSave }: Props) => {
  return (
    <Stack spacing={3} maxWidth={520}>
      <PassthroughConfigurationHeader />
      <Stack spacing={3}>
        <Stack spacing={2}>
          <FormControl>
            <InputLabel>Region</InputLabel>
            <Select
              label="Region"
              value={state.configuration.environment}
              onChange={(e) => {
                onConfigurationChange({
                  ...state.configuration,
                  environment: e.target.value as PassthroughEnvironment,
                });
              }}
              disabled={disabled}
            >
              {Array.from(regionConfigurationOptions.entries()).map(([value, label]) => (
                <MenuItem key={value} value={value}>
                  <Typography>{label}</Typography>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl>
            <TextField
              label="API Key"
              placeholder="API Key"
              value={state.configuration.apiKey}
              onChange={(e) =>
                onConfigurationChange({
                  ...state.configuration,
                  apiKey: e.target.value,
                })
              }
              disabled={disabled}
            />
          </FormControl>
        </Stack>
      </Stack>
      <Stack flexDirection={"row"} display={disabled ? "none" : "flex"}>
        <Button variant="contained" onClick={onConfigurationSave} disabled={!state.isConfigurationDirty}>
          Save
        </Button>
      </Stack>
    </Stack>
  );
};

export default PassthroughConfigurationContainer;
