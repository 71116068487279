import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import adminApi from "../../../../api/adminApi";
import { DataCollectionRequestDetails } from "../../../../api/types/dataCollectionTypes";
import EmailIcon from "../../../../icons/EmailIcon";

interface Props {
  dataRequestId: string;
  submissionIds: string[];
  recipientNames: string[];
  open: boolean;
  onClose: () => void;
  onApply: (dataRequest: DataCollectionRequestDetails) => void;
}

const sendSubmissionReminders = withErrorHandling(adminApi.sendSubmissionReminders);

const SendSubmissionRemindersDialog = ({
  dataRequestId,
  submissionIds,
  recipientNames,
  open,
  onClose,
  onApply,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);

  const handleApply = async () => {
    setSaving(true);
    const [dataRequest, error] = await sendSubmissionReminders(dataRequestId, { submissionIds });
    setSaving(false);

    if (error) {
      logError(error, "[SendSubmissionRemindersDialog] sendSubmissionReminders");
      sendNotificationError("Failed to send reminders");
      return;
    }

    sendNotification(`Successfully sent reminder to the recipients of ${recipientNames.length} companies`);
    onApply(dataRequest);
  };

  const description = `Send a reminder to the recipients of ${recipientNames.length === 1 ? recipientNames[0] : recipientNames.length + " portfolio companies"} to submit this request.`;

  return (
    <Dialog open={open} onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>Reminder</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={3}>
          <EmailIcon sx={{ height: 45, width: 61 }} />
          <Stack alignItems="center" spacing={1}>
            <Typography variant="h6" textAlign="center">
              Send reminders
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              {description}
            </Typography>
          </Stack>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={isSaving} onClick={handleApply}>
          Send
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SendSubmissionRemindersDialog;
