import { useCallback } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import { ApiError } from "../../../../shared/api/types";
import useIsMounted from "../../../../shared/hooks/useIsMounted";
import { wait } from "../../../../shared/utilities/promiseHelper";
import adminApi from "../../../api/adminApi";
import { CreateDataImportRequest, DataImportInfo, DataImportMetadata } from "../../../api/types/dataImportTypes";
import { OperationStatus, StartDataImportOptions } from "./importDataPagesTypes";

const createDataImport = withErrorHandling(adminApi.createDataImport);
const startDataImportParsingAndValidation = withErrorHandling(adminApi.startDataImportParsingAndValidation);
const getDataImportValidationStatus = withErrorHandling(adminApi.getDataImportValidationStatus);
const getDataImportDetails = withErrorHandling(adminApi.getDataImportDetails);
const getDataImportMetadata = withErrorHandling(adminApi.getDataImportMetadata);
const startApplyingDataImport = withErrorHandling(adminApi.startApplyingDataImport);
const getDataImportApplicationStatus = withErrorHandling(adminApi.getDataImportApplicationStatus);
const startDataImportRollback = withErrorHandling(adminApi.startDataImportRollback);
const getDataImportRollbackStatus = withErrorHandling(adminApi.getDataImportRollbackStatus);

const delayMs = 1000;

interface DataImportResult {
  dataImport: DataImportInfo;
  dataImportMetadata: DataImportMetadata;
}

export const useCreateDataImport = (onStatusUpdate: (operationStatus: OperationStatus) => void) => {
  const isMounted = useIsMounted();

  return useCallback(
    async (req: CreateDataImportRequest): Promise<[DataImportResult, undefined] | [undefined, ApiError]> => {
      onStatusUpdate({ statusText: "Creating data import", details: [] });

      const [createdDataImport, createError] = await createDataImport(req);
      if (createError) {
        return [undefined, createError];
      }

      const [parseResp, parseError] = await startDataImportParsingAndValidation(createdDataImport.id);
      if (parseError) {
        return [undefined, parseError];
      }

      const dataImportId = createdDataImport.id;

      while (isMounted()) {
        await wait(delayMs);

        const [statusResp, statusError] = await getDataImportValidationStatus(
          createdDataImport.id,
          parseResp.validationId
        );
        if (statusError?.type === "NotFound") {
          continue;
        }

        if (statusError) {
          return [undefined, statusError];
        }

        onStatusUpdate({
          statusText: statusResp.status,
          error: statusResp.error,
          details: [
            ["Processed records", statusResp.processedRecords.toLocaleString()],
            ["Invalid records", statusResp.invalidRecords.toLocaleString()],
          ],
        });

        if (statusResp.isFinished) {
          if (statusResp.error) {
            return [undefined, { message: statusResp.error }];
          }

          const [[dataImport, dataImportError], [dataImportMetadata, metadataError]] = await Promise.all([
            getDataImportDetails(dataImportId),
            getDataImportMetadata(dataImportId),
          ]);

          const fetchError = dataImportError || metadataError;
          return fetchError ? [undefined, fetchError] : [{ dataImport, dataImportMetadata }, undefined];
        }
      }

      return [undefined, { message: "Canceled", isCanceledRequest: true }];
    },
    [isMounted, onStatusUpdate]
  );
};

export const useValidateDataImport = (onStatusUpdate: (operationStatus: OperationStatus) => void) => {
  const isMounted = useIsMounted();

  return useCallback(
    async (importId: string): Promise<[DataImportResult, undefined] | [undefined, ApiError]> => {
      onStatusUpdate({ statusText: "Validating data import", details: [] });

      const [parseResp, parseError] = await startDataImportParsingAndValidation(importId);
      if (parseError) {
        return [undefined, parseError];
      }

      while (isMounted()) {
        await wait(delayMs);

        const [statusResp, statusError] = await getDataImportValidationStatus(importId, parseResp.validationId);
        if (statusError?.type === "NotFound") {
          continue;
        }

        if (statusError) {
          return [undefined, statusError];
        }

        onStatusUpdate({
          statusText: statusResp.status,
          error: statusResp.error,
          details: [
            ["Processed records", statusResp.processedRecords.toLocaleString()],
            ["Invalid records", statusResp.invalidRecords.toLocaleString()],
          ],
        });

        if (statusResp.isFinished) {
          if (statusResp.error) {
            return [undefined, { message: statusResp.error }];
          }

          const [[dataImport, dataImportError], [dataImportMetadata, metadataError]] = await Promise.all([
            getDataImportDetails(importId),
            getDataImportMetadata(importId),
          ]);

          const fetchError = dataImportError || metadataError;
          return fetchError ? [undefined, fetchError] : [{ dataImport, dataImportMetadata }, undefined];
        }
      }

      return [undefined, { message: "Canceled", isCanceledRequest: true }];
    },
    [isMounted, onStatusUpdate]
  );
};

export const useApplyDataImport = (onStatusUpdate: (operationStatus: OperationStatus) => void) => {
  const isMounted = useIsMounted();

  return useCallback(
    async (importId: string, options: StartDataImportOptions): Promise<[true, undefined] | [false, ApiError]> => {
      onStatusUpdate({ statusText: "Applying data import", details: [] });

      const [applyResp, applyError] = await startApplyingDataImport(importId, {
        reapplyAlreadyImportedRecords: false,
        ignoreInvalidRecords: true,
        ignoreUpdatesInRecordsValidation: false,
        sendEmailNotificationWhenFinished: options.sendEmailNotificationWhenFinished,
      });

      if (applyError) {
        return [false, applyError];
      }

      while (isMounted()) {
        await wait(delayMs);

        const [statusResp, statusError] = await getDataImportApplicationStatus(importId, applyResp.applicationId);
        if (statusError?.type === "NotFound") {
          continue;
        }

        if (statusError) {
          return [false, statusError];
        }

        onStatusUpdate({
          statusText: statusResp.status,
          error: statusResp.error,
          details: [
            ["Processed records", statusResp.processedRecords.toLocaleString()],
            ["Failed records", statusResp.failedRecords.toLocaleString()],
            ["Excluded records", statusResp.excludedRecords.toLocaleString()],
          ],
        });

        if (statusResp.isCompleted) {
          return statusResp.error ? [false, { message: statusResp.error }] : [true, undefined];
        }
      }

      return [false, { message: "Canceled", isCanceledRequest: true }];
    },
    [isMounted, onStatusUpdate]
  );
};

export const useRollbackDataImport = (onStatusUpdate: (operationStatus: OperationStatus) => void) => {
  const isMounted = useIsMounted();

  return useCallback(
    async (importId: string): Promise<[true, undefined] | [false, ApiError]> => {
      onStatusUpdate({
        statusText: "Rollback started",
        details: [],
      });

      const [rollbackResp, rollbackError] = await startDataImportRollback(importId);

      if (rollbackError) {
        return [false, rollbackError];
      }

      while (isMounted()) {
        await wait(delayMs);

        const [statusResp, statusError] = await getDataImportRollbackStatus(importId, rollbackResp.rollbackId);
        if (statusError?.type === "NotFound") {
          continue;
        }

        if (statusError) {
          return [false, statusError];
        }

        onStatusUpdate({
          statusText: statusResp.status,
          error: statusResp.error,
          details: [
            ["Processed records", statusResp.processedRecords.toLocaleString()],
            ["Failed records", statusResp.failedRecords.toLocaleString()],
          ],
        });

        if (statusResp.isCompleted) {
          return statusResp.error ? [false, { message: statusResp.error }] : [true, undefined];
        }
      }

      return [false, { message: "Canceled", isCanceledRequest: true }];
    },
    [isMounted, onStatusUpdate]
  );
};
