import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Chip,
  DialogActions,
  Divider,
  FormControl,
  InputLabel,
  ListItemText,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import LoaderBox from "../../../../components/LoaderBox";
import { MenuItemStyled } from "../../../../components/MenuItemStyled";
import PromptDialog from "../../../../components/dialog/PromptDialog";
import PromptDialogHeader from "../../../../components/dialog/PromptDialogHeader";
import TextFieldExt from "../../../../components/inputs/TextFieldExt";
import useDebounce from "../../../../hooks/useDebounce";
import useFetch from "../../../../hooks/useFetch";
import { logError } from "../../../../logging";
import { formatString } from "../../../../utilities/stringFormatter";
import { ValidationResult, validResult } from "../../../../utilities/validators";
import { default as biClientShared, default as sharedBiClient } from "../../../api/biClient";
import { ClientInfo, FundType, ReportConfiguration, ReportGroup, ReportTemplate } from "../../../api/biClient.types";
import { useSharedReportingLocalization } from "../../../hooks/useLocalization";
import DataSource from "../DataSources";
import { formatFundType } from "../helpers/reportTemplateHelper";
import { FundTypeValidator, MainInfoValidator, TemplateDescriptionValidator } from "./SaveTemplateDialog.validator";

export interface SaveTemplateDialogProps {
  template: ReportTemplate;
  templateConfiguration: ReportConfiguration | undefined;
  title: string;
  companies: ClientInfo[];
  onClose: () => void;
  onSaved: (template: ReportTemplate, dataSourceChanged: boolean) => void;
}

export default function SaveTemplateDialog({
  template,
  templateConfiguration,
  title,
  companies,
  onClose,
  onSaved,
}: SaveTemplateDialogProps) {
  const { report_templates: locale } = useSharedReportingLocalization();
  const [error, setError] = useState<string>();
  const [isLoading, setLoading] = useState(false);
  const [templateToSave, setTemplateToSave] = useState(() => {
    return {
      ...template,
      dataSource: companies.find((c) => c.clientCode === template.dataSource)
        ? template.dataSource
        : companies.at(0)?.clientCode || "",
    };
  });
  const [isFormValid, setIsFormValid] = useState<boolean>(false);

  const fetchGroups = useCallback(
    () => biClientShared.reportTemplateGroup.getReportGroups(template.organization),
    [template.organization]
  );

  const [groups, groupsError, { isFetching: isFetchingGroups }] = useFetch(fetchGroups);

  const validateTemplateNameAsync = useCallback(async () => {
    setLoading(true);
    try {
      const templateId = !template.reportId ? undefined : template.reportId;
      const result = await sharedBiClient.validateTemplateName({
        templateId: templateId,
        name: templateToSave.name.trim(),
        organization: template.organization,
      });
      if (!result.success || result.error) {
        setError(locale.rename_error);
        return false;
      }
      if (result.success && result.data.nameExists === true) {
        setError(formatString(locale.name_exist, templateToSave.name));
        return false;
      }
      return true;
    } catch (e) {
      logError(e, "[SaveTemplateDialog] validateTemplateName");
      setError(locale.rename_error);
      return false;
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template.reportId, template.organization, templateToSave.name]);

  const save = useCallback(
    async (newTemplate: ReportTemplate) => {
      setLoading(true);
      const isNew = template.reportId === undefined;
      const trimmedTemplate = { ...newTemplate, name: newTemplate.name.trim() };
      try {
        const response = isNew
          ? await sharedBiClient.addReportTemplate({ template: trimmedTemplate })
          : await sharedBiClient.updateReportTemplate({ template: trimmedTemplate });
        if (response.success && response.data !== undefined) {
          onSaved(response.data, trimmedTemplate.dataSource !== template.dataSource);
        } else {
          setError(locale.saving_error);
        }
      } catch {
        setError(locale.saving_error);
      } finally {
        setLoading(false);
      }
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [template.dataSource, template.reportId]
  );

  const handleSave = useCallback(
    async (template: ReportTemplate) => {
      if (isFormValid && (await validateTemplateNameAsync())) {
        if (templateConfiguration !== undefined) {
          template.configuration = templateConfiguration;
        }
        await save(template);
      }
    },
    [validateTemplateNameAsync, isFormValid, templateConfiguration, save]
  );

  useEffect(() => {
    setIsFormValid(
      MainInfoValidator(templateToSave.name).isValid &&
        FundTypeValidator(templateToSave.fundTypes).isValid &&
        TemplateDescriptionValidator(templateToSave.description).isValid
    );
  }, [templateToSave.name, templateToSave.fundTypes, templateToSave.description]);

  useEffect(() => {
    const groupId = groups?.at(0)?.id;
    if (groups !== undefined && groups.length > 0 && !templateToSave.groupId && groupId) {
      setTemplateToSave((prev) => ({ ...prev, groupId }));
    }
  }, [groups, templateToSave.groupId]);

  return (
    <PromptDialog open={true} sx={{ minWidth: 600, height: "100%", maxHeight: 735, p: 0 }}>
      <PromptDialogHeader text={title} disabled={isLoading} onClose={onClose} />
      <Divider />
      {error && (
        <Alert sx={{ mt: 2, mx: 3 }} severity="error">
          {error}
        </Alert>
      )}
      {groupsError && (
        <Alert sx={{ mt: 2, mx: 3 }} severity="error">
          {locale.fetching_error}
        </Alert>
      )}
      <Stack sx={{ gap: 2, pl: 3, pr: 1, py: 2, flex: 1, overflowY: "scroll" }}>
        <LoaderBox loading={isFetchingGroups}>
          <MainInfoBlock
            templateName={templateToSave.name}
            saving={isLoading}
            onSetName={(name) => setTemplateToSave((prev) => ({ ...prev, name: name }))}
            groupId={templateToSave.groupId ?? ""}
            onSetGroupId={(groupId) => setTemplateToSave((prev) => ({ ...prev, groupId: groupId }))}
            groups={groups || []}
          />
          <FundTypeBlock
            selectedTypes={templateToSave.fundTypes}
            onChange={(types) => setTemplateToSave((prev) => ({ ...prev, fundTypes: types }))}
          />
          <DataSource
            title="Default Data Source"
            disclaimer={locale.change_datasource_disclaimer}
            dataSource={templateToSave.dataSource}
            companies={companies}
            onChange={(company) => setTemplateToSave((prev) => ({ ...prev, dataSource: company }))}
            isLoading={isLoading}
          />
          <TemplateDescriptionBlock
            description={templateToSave.description}
            onChange={(value) => setTemplateToSave((prev) => ({ ...prev, description: value }))}
          />
        </LoaderBox>
      </Stack>
      <Divider />

      <DialogActions sx={{ px: 3, py: 2 }}>
        <Button variant="text" color="secondary" disabled={isLoading} onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={isLoading || !isFormValid || isFetchingGroups}
          loading={isLoading}
          onClick={() => handleSave(templateToSave)}
        >
          Save
        </Button>
      </DialogActions>
    </PromptDialog>
  );
}

interface TemplateDescriptionBlockProps {
  description: string;
  disabled?: boolean;
  onChange: (description: string) => void;
}

function TemplateDescriptionBlock({ description, onChange, disabled }: TemplateDescriptionBlockProps) {
  const { report_templates: locale } = useSharedReportingLocalization();
  const debouncedDescription = useDebounce((v: string) => onChange(v), 300);
  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="subtitle2">{locale.template_form.description_title}</Typography>
      </Stack>
      <TextFieldExt
        name="description"
        textFieldProps={{
          size: "small",
          label: "Description",
          variant: "outlined",
          placeholder: locale.template_form.description_placeholder,
          disabled: disabled,
          multiline: true,
          rows: 8,
          defaultValue: description,
        }}
        doValidate={TemplateDescriptionValidator}
        onValidated={(_, result) => result.error}
        onValueChanged={debouncedDescription}
      />
    </Stack>
  );
}

interface FundTypeBlockProps {
  selectedTypes: FundType[];
  onChange: (types: FundType[]) => void;
}

function FundTypeBlock({ selectedTypes, onChange }: FundTypeBlockProps) {
  const [validationResult, setValidationResult] = useState<ValidationResult>(validResult());
  const { report_templates: locale } = useSharedReportingLocalization();
  const valueChanged = () => {
    const validationResult = FundTypeValidator(selectedTypes);
    setValidationResult(validationResult);
  };

  return (
    <Stack gap={2}>
      <Stack gap={1}>
        <Typography variant="subtitle2">
          {locale.template_form.fund_type_title}
          <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
            *
          </Typography>
        </Typography>
        <Typography>{locale.template_form.fund_type_disclaimer}</Typography>
      </Stack>
      <Autocomplete
        multiple
        disablePortal
        value={selectedTypes || []}
        options={Object.keys(FundType) as FundType[]}
        disableCloseOnSelect
        getOptionLabel={(option) => option}
        renderTags={(selectedOpts, getTagProps) =>
          selectedOpts.map((option, index) => {
            const { key, ...tagProps } = getTagProps({ index });
            return (
              <Chip
                variant="outlined"
                label={<Typography>{formatFundType(option)}</Typography>}
                {...tagProps}
                key={index}
                sx={{ borderColor: "#E5E6E9" }}
              />
            );
          })
        }
        renderOption={(props, option, { selected }) => (
          <MenuItemStyled {...props} key={option}>
            <Checkbox checked={selected} />
            <ListItemText primary={formatFundType(option)} />
          </MenuItemStyled>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            slotProps={{
              input: {
                ...params.InputProps,
                sx: { gap: 1 },
                readOnly: true,
              },
            }}
            onBlur={() => valueChanged()}
            aria-readonly={"true"}
            error={!validationResult.isValid}
            helperText={validationResult.error}
          />
        )}
        onChange={(_, newValue) => {
          onChange(newValue);
        }}
        sx={{
          ".MuiAutocomplete-tag": {
            m: 0,
          },
        }}
      />
    </Stack>
  );
}

interface MainInfoBlockProps {
  templateName: string;
  groupId: string;
  groups: ReportGroup[];
  saving: boolean;
  onSetName: (name: string) => void;
  onSetGroupId: (groupId: string) => void;
}

function MainInfoBlock({ templateName, saving, onSetName, groupId, onSetGroupId, groups }: MainInfoBlockProps) {
  const { report_templates: locale } = useSharedReportingLocalization();
  return (
    <Stack gap={2.5}>
      <Typography variant="subtitle2">
        {locale.template_form.main_info_title}
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }}>
          *
        </Typography>
      </Typography>
      <TextFieldExt
        name="name"
        textFieldProps={{
          size: "small",
          label: "Name",
          variant: "outlined",
          value: templateName,
          sx: { flex: 1 },
          placeholder: locale.template_form.main_info_placeholder,
          disabled: saving,
        }}
        doValidate={MainInfoValidator}
        onValidated={(_, result) => result}
        onValueChanged={(value) => onSetName(value)}
      />
      <FormControl fullWidth disabled={saving}>
        <InputLabel>{locale.template_form.group_title}</InputLabel>
        <Select
          value={groups.length > 0 ? groupId : ""}
          label={locale.template_form.group_title}
          onChange={(evt) => onSetGroupId(evt.target.value)}
        >
          {groups.map((rg) => {
            return (
              <MenuItemStyled key={rg.id} value={rg.id}>
                <Typography>{rg.caption}</Typography>
              </MenuItemStyled>
            );
          })}
        </Select>
      </FormControl>
    </Stack>
  );
}
