import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../shared/logging";
import {
  combineValidators,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
} from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { ObjectAccessCategory } from "../../../../api/types/objectTypes";
import { useDataRequestSetupPageContext } from "../data-request-templates/DataRequestSetupPageContext";
import { formatRecipientCategoryType, getRecipientCategoryTypes } from "./objectCategoriesFormatter";

interface Props {
  editedCategoryId?: string;
  onClose: () => void;
}

const createObjectAccessCategory = withErrorHandling(adminApi.createObjectAccessCategory);
const updateObjectAccessCategory = withErrorHandling(adminApi.updateObjectAccessCategory);

const validateForm = (
  name: string,
  categoryType: string,
  existingCategories: ObjectAccessCategory[],
  editedCategoryId: string | undefined
) => {
  const otherCategoryNames = existingCategories
    .filter((c) => c.id !== editedCategoryId && c.type === categoryType)
    .map((c) => c.name);

  const validateName = combineValidators(
    requiredValidator,
    maxCharactersValidator(50),
    uniqueValidator("A category with this name already exists", otherCategoryNames)
  );

  const validateCategoryType = requiredValidator;

  const nameResult = validateName(name);
  const categoryTypeResult = validateCategoryType(categoryType);

  return {
    isValid: nameResult.isValid && categoryTypeResult.isValid,
    nameResult,
    categoryTypeResult,
  };
};

const EditDataRequestCategoryDialog = ({ editedCategoryId, onClose }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { categories, objectDefinition, onCategoriesUpdated } = useDataRequestSetupPageContext();

  const [name, setName] = useState(
    editedCategoryId ? (categories.find((c) => c.id === editedCategoryId)?.name ?? "") : ""
  );
  const [categoryType, setCategoryType] = useState(
    editedCategoryId ? (categories.find((c) => c.id === editedCategoryId)?.type ?? "") : ""
  );
  const [touchedFields, setTouchedFields] = useState(new Set<"name" | "categoryType">());
  const [loading, setLoading] = useState(false);

  const validationResults = useMemo(
    () => validateForm(name, categoryType, categories, editedCategoryId),
    [categories, categoryType, editedCategoryId, name]
  );

  const isNew = !editedCategoryId;

  const handleCreate = async () => {
    setLoading(true);

    const [newCategory, error] = await createObjectAccessCategory({
      objectType: objectDefinition.objectType,
      name,
      type: categoryType,
    });

    setLoading(false);

    if (error) {
      logError(error, "[EditDataRequestCategoryDialog] createObjectAccessCategory");
      sendNotificationError("Could not create category");
      return;
    }

    sendNotification("Category created successfully");
    onClose();
    onCategoriesUpdated([...categories, newCategory]);
  };

  const handleUpdate = async () => {
    if (!editedCategoryId) {
      return;
    }

    setLoading(true);

    const [updatedCategory, error] = await updateObjectAccessCategory(editedCategoryId, {
      objectType: objectDefinition.objectType,
      name,
    });

    setLoading(false);

    if (error) {
      logError(error, "[EditDataRequestCategoryDialog] updateObjectAccessCategory");
      sendNotificationError("Could not update category");
      return;
    }

    sendNotification("Category updated successfully");
    onClose();
    onCategoriesUpdated(categories.map((c) => (c.id === updatedCategory.id ? updatedCategory : c)));
  };

  const handleSubmit = async () => {
    if (isNew) {
      await handleCreate();
    } else {
      await handleUpdate();
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setTouchedFields((prev) => new Set(prev).add("name"));
  };

  const handleCategoryTypeChange = (e: SelectChangeEvent) => {
    setCategoryType(e.target.value);
    setTouchedFields((prev) => new Set(prev).add("categoryType"));
  };

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>{isNew ? "New Category" : "Edit Category"}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2}>
          <FormControl fullWidth>
            <TextField
              value={name}
              onChange={handleNameChange}
              label="Name"
              error={touchedFields.has("name") && !validationResults.nameResult.isValid}
              helperText={touchedFields.has("name") && validationResults.nameResult.error}
            />
          </FormControl>

          <FormControl fullWidth disabled={!isNew}>
            <InputLabel id="recipient-type-select-label">Recipient Type</InputLabel>
            <Select
              label="Recipient Type"
              value={categoryType}
              onChange={handleCategoryTypeChange}
              error={touchedFields.has("categoryType") && !validationResults.categoryTypeResult.isValid}
            >
              {getRecipientCategoryTypes(objectDefinition).map((categoryType) => (
                <MenuItem key={categoryType} value={categoryType}>
                  {formatRecipientCategoryType(categoryType, objectDefinition)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={loading} onClick={handleSubmit} disabled={!validationResults.isValid}>
          {isNew ? "Create" : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditDataRequestCategoryDialog;
