import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItem,
  TextField,
  Typography,
} from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../shared/components/DialogeCloseButton";
import InlineLoader from "../../../../../../shared/components/inlineLoader/InlineLoader";
import ListWithLazyRender from "../../../../../../shared/components/ListWithLazyRender";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../../shared/logging";
import { stringComparerBy } from "../../../../../../shared/utilities/arrayHelper";
import adminApi from "../../../../../api/adminApi";
import { DataCollectionRequestDetails } from "../../../../../api/types/dataCollectionTypes";

const addSubmissionsToDataRequest = withErrorHandling(adminApi.addSubmissionsToDataRequest);

interface Props {
  dataRequestId: string;
  assignedObjectIds: string[];
  onClose: () => void;
  onApply: (dataRequest: DataCollectionRequestDetails) => void;
}

const AddSubmissionsDialog = ({ dataRequestId, assignedObjectIds, onClose, onApply }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const [loading, setLoading] = useState(false);
  const [selectedObjectIds, setSelectedObjectIds] = useState<string[]>([]);

  const getObjects = useCallback(() => adminApi.searchObjects("PortfolioCompany", { fieldIds: [] }), []);

  const [objects, fetchError, { isFetching }] = useFetch(getObjects);

  const options = useMemo(
    () => (objects?.items ?? []).filter((o) => !assignedObjectIds.includes(o.id)).sort(stringComparerBy((o) => o.name)),
    [assignedObjectIds, objects?.items]
  );

  const handleSubmit = async () => {
    setLoading(true);
    const [dataRequest, error] = await addSubmissionsToDataRequest(dataRequestId, {
      recipientObjectIds: selectedObjectIds,
    });
    setLoading(false);

    if (error) {
      logError(error, "[DeleteDataCollectionRequestDialog] addSubmissionsToDataRequest");
      sendNotificationError("Could not create submissions");
      return;
    }

    sendNotification("Submissions created successfully");
    onApply(dataRequest);
  };

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>Add Companies</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        {isFetching && <InlineLoader />}

        {fetchError && (
          <Typography variant="subtitle2" color="error">
            Failed to load available companies
          </Typography>
        )}

        {objects !== undefined && options.length === 0 && (
          <Typography variant="subtitle2" color="text.secondary">
            No companies available to add
          </Typography>
        )}

        {!isFetching && !fetchError && objects !== undefined && options.length > 0 && (
          <Autocomplete
            multiple
            fullWidth
            options={options}
            getOptionLabel={(o) => o.name}
            onChange={(_, items) => items && setSelectedObjectIds(items.map((i) => i.id))}
            renderInput={(inputProps) => <TextField {...inputProps} autoFocus placeholder="Type company name..." />}
            renderOption={(props, option) => (
              <ListItem {...props} key={option.id}>
                <Typography>{option.name}</Typography>
              </ListItem>
            )}
            slotProps={{ listbox: { component: ListWithLazyRender } }}
          />
        )}
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" loading={loading} onClick={handleSubmit} disabled={selectedObjectIds.length === 0}>
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubmissionsDialog;
