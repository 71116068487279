import { Button, Container, Divider, Stack } from "@mui/material";
import deepEqual from "fast-deep-equal";
import { useCallback, useMemo, useState } from "react";
import { createApiResponse, withErrorHandling } from "../../../../../shared/api/axiosHelper";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import InlineLoader from "../../../../../shared/components/inlineLoader/InlineLoader";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import { logError } from "../../../../../shared/logging";
import { emailValidator, requiredValidator, validResult } from "../../../../../shared/utilities/validators";
import adminApi from "../../../../api/adminApi";
import { InvestorPortalSettings } from "../../../../api/types/investorPortalTypes";
import { useClientContext } from "../../../../context/ClientContext";
import EmailTemplateEditor, { EmailTemplateSettings } from "../../../common/email-templates/EmailTemplateEditor";
import AutomaticInvitesSection from "./AutomaticInvitesSection";
import SendTestInvitationEmail from "./SendTestInvitationEmail";

const snippets = [
  { value: "{{FundName}}", displayName: "Fund Name" },
  { value: "{{InvestorName}}", displayName: "Investor Name" },
  { value: "{{RecipientName}}", displayName: "Contact Name" },
];

interface SettingsForm {
  enableAutomaticInvites: boolean;
  subject: string;
  body: string;
  cc: string;
  bcc: string;
}

const updateInvestorPortalSettings = withErrorHandling(adminApi.updateInvestorPortalSettings);

const splitCommaSeparatedString = (str: string) =>
  str
    .split(",")
    .map((s) => s.trim())
    .filter(Boolean);

const validateCommaSeparatedEmailString = (str: string) => {
  const emails = splitCommaSeparatedString(str);
  for (const email of emails) {
    const result = emailValidator(email);
    if (!result.isValid) {
      return result;
    }
  }

  return validResult();
};

const validateSettings = (settings: SettingsForm) => {
  return {
    subject: requiredValidator(settings.subject),
    body: requiredValidator(settings.body),
    cc: validateCommaSeparatedEmailString(settings.cc),
    bcc: validateCommaSeparatedEmailString(settings.bcc),
  };
};

const initFromSavedSettings = (savedSettings: InvestorPortalSettings): SettingsForm => ({
  enableAutomaticInvites: savedSettings.enableAutomaticInvites,
  subject: savedSettings.contactInvitationEmailSubject,
  body: savedSettings.contactInvitationEmailMessageText,
  cc: savedSettings.contactInvitationEmailCC,
  bcc: savedSettings.contactInvitationEmailBCC,
});

const InvitationEmailSection = () => {
  const { sendNotification, sendNotificationError } = useNotificationContext();
  const { hasPermissions } = useClientContext();

  const [currentSettings, setCurrentSettings] = useState<SettingsForm>();
  const [isSaving, setSaving] = useState(false);

  const [savedSettings, fetchSettingsError, { setData: setSavedSettings }] = useFetch(
    adminApi.getInvestorPortalSettings,
    (data) => {
      setCurrentSettings(initFromSavedSettings(data.settings));
    }
  );

  const hasPermissionsToEdit = useMemo(() => hasPermissions(["ManageInvestorPortalSettings"]), [hasPermissions]);

  const getTestInvitationContacts = useCallback(
    () =>
      hasPermissionsToEdit
        ? adminApi.getTestInvitationContacts()
        : Promise.resolve(createApiResponse({ contacts: [] })),
    [hasPermissionsToEdit]
  );

  const [testInvitationContactsResp, fetchTestContactsError] = useFetch(getTestInvitationContacts);

  const fetchError = fetchTestContactsError || fetchSettingsError;
  if (fetchError) {
    logError(fetchError, "[InvitationEmailSection] fetch");
    return <DataLoadingFailed bgColor="none" title="Failed to load settings" />;
  }

  if (savedSettings === undefined || !currentSettings || testInvitationContactsResp === undefined) {
    return <InlineLoader />;
  }

  const handleSave = async () => {
    const enableAutomaticInvites = currentSettings.enableAutomaticInvites;
    const contactInvitationEmailSubject = currentSettings.subject.trim();
    const contactInvitationEmailMessageText = currentSettings.body.trim();
    const contactInvitationEmailCC = splitCommaSeparatedString(currentSettings.cc).join(",");
    const contactInvitationEmailBCC = splitCommaSeparatedString(currentSettings.bcc).join(",");

    setSaving(true);

    const [resp, error] = await updateInvestorPortalSettings({
      enableAutomaticInvites,
      contactInvitationEmailSubject,
      contactInvitationEmailMessageText,
      contactInvitationEmailCC,
      contactInvitationEmailBCC,
    });

    setSaving(false);

    if (error) {
      logError(error, "[InvitationEmailEditor]");
      sendNotificationError("Could not save invitation email settings");
      return;
    }

    sendNotification("Invitation email settings saved");
    setSavedSettings(resp);
    setCurrentSettings(initFromSavedSettings(resp.settings));
  };

  const handleReset = () => {
    setCurrentSettings(initFromSavedSettings(savedSettings.settings));
  };

  const handleEmailSettingsChange = (update: Partial<EmailTemplateSettings>) =>
    setCurrentSettings((prev) =>
      prev
        ? {
            ...prev,
            ...update,
          }
        : undefined
    );

  const handleAutomaticInvitesChange = (checked: boolean) => {
    setCurrentSettings((prev) =>
      prev
        ? {
            ...prev,
            enableAutomaticInvites: checked,
          }
        : undefined
    );
  };

  const isDirty =
    !!savedSettings &&
    !deepEqual(
      {
        enableAutomaticInvites: savedSettings.settings.enableAutomaticInvites,
        subject: savedSettings.settings.contactInvitationEmailSubject,
        body: savedSettings.settings.contactInvitationEmailMessageText,
        cc: savedSettings.settings.contactInvitationEmailCC,
        bcc: savedSettings.settings.contactInvitationEmailBCC,
      },
      currentSettings
    );

  const settingsValidationResults = validateSettings(currentSettings);

  const areSettingsValid = Object.values(settingsValidationResults).every((result) => result.isValid);

  return (
    <Container maxWidth="md" disableGutters>
      <Stack py={2.5} spacing={3}>
        <AutomaticInvitesSection
          enabled={currentSettings.enableAutomaticInvites}
          onChange={handleAutomaticInvitesChange}
          disabled={!hasPermissionsToEdit}
        />

        <Stack spacing={3}>
          <EmailTemplateEditor
            snippets={snippets}
            settings={currentSettings}
            onChange={handleEmailSettingsChange}
            disabled={!hasPermissionsToEdit}
            validationResults={isDirty ? settingsValidationResults : {}}
          />

          <Stack direction="row" spacing={1}>
            <Button
              variant="contained"
              color="primary"
              disabled={!isDirty || !areSettingsValid || !hasPermissionsToEdit}
              loading={isSaving}
              onClick={handleSave}
            >
              Save
            </Button>
            <Button variant="text" color="secondary" disabled={!isDirty || !hasPermissionsToEdit} onClick={handleReset}>
              Cancel
            </Button>
          </Stack>
        </Stack>

        {hasPermissionsToEdit && testInvitationContactsResp.contacts.length > 0 && (
          <>
            <Divider />
            <SendTestInvitationEmail testInvitationContacts={testInvitationContactsResp.contacts} disabled={isDirty} />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default InvitationEmailSection;
