import ImportDataIcon from "@mui/icons-material/ExitToAppRounded";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { DataImportInfo } from "../../../api/types/dataImportTypes";
import { StartDataImportOptions } from "./importDataPagesTypes";

interface Props {
  dataImport: DataImportInfo;
  errorsCount: number;
  onClose: () => void;
  onConfirm: (options: StartDataImportOptions) => void;
}

const StartImportConfirmationDialog = ({ dataImport, errorsCount, onClose, onConfirm }: Props) => {
  const [startOptions, setStartOptions] = useState<StartDataImportOptions>({
    sendEmailNotificationWhenFinished: dataImport.applicationOptions === "AppliedAsynchronouslyByExternalService",
  });

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>Start Data Import</DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <DialogContent>
        <Stack spacing={2} alignItems="center">
          <ImportDataIcon color="primary" sx={{ width: 80, height: 80 }} />

          <Typography variant="h6">Start data import?</Typography>

          {errorsCount > 0 && (
            <Typography color="warning.dark">
              This data import contains {errorsCount} rows with errors. They will be excluded from the final import.
            </Typography>
          )}

          <Stack spacing={1}>
            {dataImport.applicationOptions === "AppliedAsynchronouslyByExternalService" && (
              <Typography color="text.secondary">
                This data import will be applied asynchronously by the system. The application process might take some
                time. To receive a notification regarding the completion of the application, please keep the box below
                checked.
              </Typography>
            )}

            <FormControlLabel
              label="Send notification email when finished"
              control={
                <Checkbox
                  checked={startOptions.sendEmailNotificationWhenFinished}
                  onChange={(_, checked) =>
                    setStartOptions((prev) => ({ ...prev, sendEmailNotificationWhenFinished: checked }))
                  }
                />
              }
            />
          </Stack>
        </Stack>
      </DialogContent>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Continue Editing
        </Button>
        <Button variant="contained" onClick={() => onConfirm(startOptions)}>
          Start Import
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default StartImportConfirmationDialog;
