import {
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../../../../shared/logging";
import { stringComparerBy } from "../../../../../../../shared/utilities/arrayHelper";
import {
  combineValidators,
  maxCharactersValidator,
  requiredValidator,
  uniqueValidator,
  ValidationResult,
} from "../../../../../../../shared/utilities/validators";
import adminApi, { FundWithFieldValues } from "../../../../../../api/adminApi";
import { Fundraising } from "../../../../../../api/types/fundraisingTypes";
import { SelectOption } from "../../../../../common/filters/filterTypes";
import { useFundraisingDetailsPageContext } from "../FundraisingDetailsPageContext";

interface Props {
  allFunds: FundWithFieldValues[];
  onSubmit: (updatedFundraising: Fundraising) => void;
  onAsyncOperationStart: (asyncOperationId: string) => void;
  onClose: () => void;
}

interface FormState {
  newFundName: string;
  validationResult: ValidationResult;
  copyFromFundEnabled: boolean;
  templateFund: SelectOption | null;
}

const updateFundraisingWithNewFund = withErrorHandling(adminApi.updateFundraisingWithNewFund);

const maxNameLength = 100;

const CreateFundDialog = ({ allFunds, onSubmit, onAsyncOperationStart, onClose }: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const { fundraising, onConfirmSave } = useFundraisingDetailsPageContext();

  const existingFundNames = useMemo(() => allFunds.map((fund) => fund.name), [allFunds]);

  const copyFromFundOptions = useMemo(
    () => allFunds.map((fund) => ({ value: fund.id, label: fund.name })).sort(stringComparerBy((o) => o.label)),
    [allFunds]
  );

  const [isSaving, setSaving] = useState(false);

  const [formState, setFormState] = useState<FormState>({
    newFundName: "",
    validationResult: { isValid: false, error: "" },
    copyFromFundEnabled: false,
    templateFund: null,
  });

  const save = async () => {
    setSaving(true);
    const [resp, error] = await updateFundraisingWithNewFund(fundraising.id, {
      newFundraisingFundName: formState.newFundName.trim(),
      copyInvestorsFromFundId: formState.copyFromFundEnabled ? formState.templateFund?.value : undefined,
    });
    setSaving(false);

    if (error) {
      logError(error, "[CreateFundDialog]");
      sendNotificationError("Could not save fundraising with new fund");
    } else {
      sendNotification("Fundraising updated successfully with new fund");
      onClose();

      if (resp.fundraising) {
        onSubmit(resp.fundraising);
      } else {
        onAsyncOperationStart(resp.asyncOperationId);
      }
    }
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const validateFundName = combineValidators(
      requiredValidator,
      maxCharactersValidator(maxNameLength),
      uniqueValidator("A fund with this name already exists", existingFundNames)
    );

    setFormState((prev) => ({
      ...prev,
      newFundName: e.target.value,
      validationResult: validateFundName(e.target.value.toLowerCase()),
    }));
  };

  const handleCopyFromFundChange = (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setFormState((prev) => ({
      ...prev,
      copyFromFundEnabled: checked,
      templateFund: checked ? prev.templateFund : null,
    }));
  };

  const handleSave = () => onConfirmSave(save);

  return (
    <Dialog open onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create Fund</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack spacing={2.5} alignItems="flex-start">
          <TextField
            fullWidth
            label="Name"
            value={formState.newFundName}
            onChange={handleNameChange}
            helperText={formState.validationResult.error}
            error={Boolean(formState.validationResult.error)}
            slotProps={{
              htmlInput: { maxLength: maxNameLength },
              input: {
                endAdornment: (
                  <Typography color="text.secondary" variant="caption">
                    {maxNameLength - formState.newFundName.length}
                  </Typography>
                ),
              },
            }}
          />

          <FormControlLabel
            control={<Checkbox checked={formState.copyFromFundEnabled} onChange={handleCopyFromFundChange} />}
            label="Copy contacts from another fund"
          />

          <Autocomplete
            disabled={!formState.copyFromFundEnabled}
            openOnFocus
            fullWidth
            options={copyFromFundOptions}
            isOptionEqualToValue={(opt, val) => opt.value === val.value}
            renderInput={(params) => <TextField {...params} placeholder="Source Fund" />}
            value={formState.templateFund}
            noOptionsText="No funds found"
            onChange={(_, newValue) => {
              if (newValue) {
                setFormState((prev) => ({
                  ...prev,
                  templateFund: newValue,
                }));
              }
            }}
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" autoFocus onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="contained"
          loading={isSaving}
          onClick={handleSave}
          disabled={!formState.validationResult.isValid}
        >
          Create & Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateFundDialog;
