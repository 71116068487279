import PublishIcon from "@mui/icons-material/LanguageRounded";
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import BinIcon from "../../../../shared/icons/BinIcon";
import { logError } from "../../../../shared/logging";
import adminApi from "../../../api/adminApi";
import { DocumentCollectionOperations } from "../../../api/types/documentCollectionTypes";
import VisibleIcon from "../../../icons/VisibleIcon";
import CombinedPaperIcon from "../../common/CombinedPaperIcon";

interface Props {
  documentCollectionIds: string[];
  operation: DocumentCollectionOperations;
  documentCollectionsInfo: string;
  onClose: () => void;
  onApply: () => void;
}

const changeDocumentCollectionsStatus = withErrorHandling(adminApi.changeDocumentCollectionsStatus);

const operationTitleMap: Record<DocumentCollectionOperations, string> = {
  MakeLive: "Publish",
  MakePreview: "Unpublish",
  Revoke: "Delete",
};

const getPrompt = (operation: DocumentCollectionOperations, documentCollectionsInfo: string) => {
  switch (operation) {
    case "MakeLive": {
      return `Publish ${documentCollectionsInfo} to Live`;
    }
    case "MakePreview": {
      return `Unpublish ${documentCollectionsInfo} to Preview`;
    }
    case "Revoke": {
      return `Delete ${documentCollectionsInfo}?`;
    }
    default: {
      return "";
    }
  }
};

const getDescription = (operation: DocumentCollectionOperations, collectionsCount: number) => {
  switch (operation) {
    case "MakeLive": {
      return `You're about to make documents "Live" and available for contacts. This process could take a few minutes.`;
    }
    case "MakePreview": {
      return `The documents will be returned in "Preview" and will not be available for viewing by contacts. This process could take a few minutes.`;
    }
    case "Revoke": {
      return `You're about to delete ${collectionsCount === 1 ? "this document collection" : collectionsCount + " document collections"}. This action is permanent and can't be undone. This process could take a few minutes.`;
    }
    default: {
      return "";
    }
  }
};

const StatusIcon = ({ operation }: { operation: DocumentCollectionOperations }) => {
  switch (operation) {
    case "MakeLive": {
      return <CombinedPaperIcon Icon={PublishIcon} />;
    }
    case "MakePreview": {
      return <CombinedPaperIcon Icon={VisibleIcon} />;
    }
    case "Revoke": {
      return <BinIcon sx={{ color: "error.light", width: 80, height: 80 }} />;
    }
    default: {
      return null;
    }
  }
};

const ChangeDocumentCollectionsStatusDialog = ({
  documentCollectionsInfo,
  documentCollectionIds,
  operation,
  onClose,
  onApply,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [reportCompletionViaEmail, setReportCompletionViaEmail] = useState(false);

  const handleApply = async () => {
    setSaving(true);
    const [, error] = await changeDocumentCollectionsStatus({
      documentCollectionIds,
      operation,
      reportCompletionViaEmail,
    });
    setSaving(false);

    if (error) {
      logError(error, "[ChangeDocumentCollectionsStatusDialog] changeDocumentCollectionsStatus");
      sendNotificationError("Failed to change status");
      return;
    }

    sendNotification("Status changed successfully");
    onApply();
    onClose();
  };

  return (
    <Dialog open onClose={onClose} slotProps={{ paper: { sx: { width: "32.5rem" } } }}>
      <DialogTitle>{operationTitleMap[operation]}</DialogTitle>
      <DialogCloseButton onClick={onClose} />

      <DialogContent>
        <Stack alignItems="center" spacing={2}>
          <StatusIcon operation={operation} />

          <Stack alignItems="center" spacing={1}>
            <Typography noWrap variant="h6">
              {getPrompt(operation, documentCollectionsInfo)}
            </Typography>
            <Typography color="text.secondary" textAlign="center">
              {getDescription(operation, documentCollectionIds.length)}
            </Typography>
          </Stack>

          <FormControlLabel
            label="Send me an email when the process is finished"
            control={
              <Checkbox
                checked={reportCompletionViaEmail}
                onChange={(_, checked) => setReportCompletionViaEmail(checked)}
              />
            }
          />
        </Stack>
      </DialogContent>

      <DialogActions sx={{ py: 2, px: 3, columnGap: 1 }}>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant={operation === "Revoke" ? "outlined" : "contained"}
          color={operation === "Revoke" ? "error" : "primary"}
          loading={isSaving}
          onClick={handleApply}
        >
          {operationTitleMap[operation]}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeDocumentCollectionsStatusDialog;
