import CachedIcon from "@mui/icons-material/CachedRounded";
import ExportIcon from "@mui/icons-material/ExitToAppRounded";
import { Button, Container, Stack } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid-premium";
import { useCallback, useState } from "react";
import DataLoadingFailed from "../../../../../shared/components/DataLoadingFailed";
import DateRangeSelect from "../../../../../shared/components/DateRangeSelect";
import RecordCounter from "../../../../../shared/components/filters/RecordCounter";
import SearchField from "../../../../../shared/components/inputs/SearchField";
import { useNotificationContext } from "../../../../../shared/contexts/NotificationContext";
import useFetch from "../../../../../shared/hooks/useFetch";
import usePageTitle from "../../../../../shared/hooks/usePageTitle";
import { logError } from "../../../../../shared/logging";
import { saveCsvFile } from "../../../../../shared/services/downloadFile";
import {
  DescriptiveDateRange,
  lastSevenDays,
  today,
  yesterday,
} from "../../../../../shared/utilities/dateFilterHelper";
import adminApi from "../../../../api/adminApi";
import { EmailTrackingStatus } from "../../../../api/types/emailsTypes";
import {
  firstPageAction,
  getPagingParams,
  loadItemsAction,
  nextPageAction,
  searchItemsAction,
} from "../../../../state/paginatedState";
import GeneralPageHeader from "../../../common/GeneralPageHeader";
import EmailDeliveryLogGrid from "./EmailDeliveryLogGrid";
import {
  clearFiltersAction,
  getInitialEmailsState,
  updateDatesFilterAction,
  updateStatusFilterAction,
} from "./emailsState";
import StatusSelect from "./filters/StatusSelect";

const emailTrackingStatusFilterMap: Record<string, EmailTrackingStatus[]> = {
  Sent: ["Sent"],
  Delivered: ["Delivered"],
  Opened: ["Opened"],
  Bounced: ["PermanentFailure"],
  "Delivered & Opened": ["Delivered", "Opened"],
};

const EmailsPage = () => {
  usePageTitle("Email Delivery Log");

  const { sendNotificationError } = useNotificationContext();

  const apiRef = useGridApiRef();

  const [state, setState] = useState(getInitialEmailsState());
  const [exporting, setExporting] = useState(false);

  const getEmails = useCallback(
    () =>
      adminApi.searchSentEmails({
        paging: getPagingParams(state.page),
        query: state.searchTerm || undefined,
        sentAtFrom: state.dateRange?.from?.toISOString(),
        sentAtTo: state.dateRange?.to?.toISOString(),
        statuses: emailTrackingStatusFilterMap[state.statusFilter],
      }),
    [state.dateRange?.from, state.dateRange?.to, state.statusFilter, state.page, state.searchTerm]
  );

  const [, error, { isFetching, fetch: fetchEmails }] = useFetch(getEmails, (data) => {
    setState(loadItemsAction(data));
  });

  if (error) {
    logError(error.message, "[EmailsPage]");
    return <DataLoadingFailed title="Loading emails failed" />;
  }

  const handleSearch = (searchTerm: string) => {
    const query = searchTerm.trim();
    if (query.length === 0 || query.length > 1) {
      apiRef.current.scrollToIndexes({ rowIndex: 0 });
      setState(searchItemsAction(query));
    }
  };

  const handleStatusChange = (filterValue: string) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    setState(updateStatusFilterAction(filterValue));
  };

  const handleDatesChange = (dateRange: DescriptiveDateRange) => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    setState(updateDatesFilterAction(dateRange));
  };

  const showClearFilters = state.dateRange !== undefined || Boolean(state.statusFilter);

  const handleClearFilters = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    setState(clearFiltersAction());
  };

  const handleRowScrollEnd = () => {
    setTimeout(() => {
      setState(nextPageAction());
    }, 100);
  };

  const handleExportToCsvClick = async () => {
    setExporting(true);
    try {
      const csv = await adminApi.getSentEmailsCsv({
        sentAtFrom: state.dateRange?.from?.toISOString(),
        sentAtTo: state.dateRange?.to?.toISOString(),
        query: state.searchTerm,
        statuses: emailTrackingStatusFilterMap[state.statusFilter],
      });

      if (!csv) {
        setExporting(false);
        sendNotificationError("Failed to export emails");
        return;
      }

      saveCsvFile(csv, "emails.csv");
    } catch (error) {
      logError(error, "[EmailsPage] getSentEmailsCsv");
      sendNotificationError("Failed to export emails");
    } finally {
      setExporting(false);
    }
  };

  const handleRefreshClick = () => {
    apiRef.current.scrollToIndexes({ rowIndex: 0 });
    if (state.page === 0) {
      fetchEmails();
    } else {
      setState(firstPageAction());
    }
  };

  return (
    <>
      <GeneralPageHeader title="Email Delivery Log" />
      <Container maxWidth={false} sx={{ height: "100%", pt: 2.5, pb: 5 }}>
        <Stack spacing={1} width="100%" height="100%">
          <Stack spacing={2} direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" spacing={2} alignItems="center">
              <RecordCounter records={state.items.length} totalRecords={state.totalRecords} />
              <DateRangeSelect
                predefinedRanges={[today, yesterday, lastSevenDays]}
                dateRange={state.dateRange}
                onChange={handleDatesChange}
              />
              <StatusSelect filterValue={state.statusFilter} onChange={handleStatusChange} />
              {showClearFilters && (
                <Button sx={{ whiteSpace: "nowrap" }} variant="text" color="primary" onClick={handleClearFilters}>
                  Clear All
                </Button>
              )}
            </Stack>
            <Stack spacing={2} direction="row" alignItems="center">
              <SearchField onSearch={handleSearch} debounceTimeMs={300} />
              <Button
                color="secondary"
                variant="outlined"
                startIcon={<CachedIcon />}
                onClick={handleRefreshClick}
                disabled={isFetching}
              >
                Refresh
              </Button>
              <Button
                loading={exporting}
                color="secondary"
                variant="outlined"
                startIcon={<ExportIcon />}
                onClick={handleExportToCsvClick}
                disabled={state.items.length === 0 || isFetching}
              >
                Export to CSV
              </Button>
            </Stack>
          </Stack>

          <EmailDeliveryLogGrid
            apiRef={apiRef}
            isLoading={isFetching}
            emails={state.items}
            onRowScrollEnd={handleRowScrollEnd}
          />
        </Stack>
      </Container>
    </>
  );
};

export default EmailsPage;
