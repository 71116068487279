import { Button, Divider, Stack, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { withErrorHandling } from "../../../../../../shared/api/axiosHelper";
import CloseIconButton from "../../../../../../shared/components/CloseIconButton";
import HorizontalFill from "../../../../../../shared/components/HorizontalFill";
import { useNotificationContext } from "../../../../../../shared/contexts/NotificationContext";
import adminApi from "../../../../../api/adminApi";
import { ObjectClassDefinition } from "../../../../../api/types/objectTypes";
import {
  FieldEditForm,
  FieldRow,
  getSanitizedForm,
  isEditFormDirty,
  isEditFormValid,
  maxFieldNameLength,
} from "../objectFieldsState";
import FieldConfigEditor from "./FieldConfigEditor";
import FieldTypeSelect from "./FieldTypeSelect";
import { getDefaultFieldConfigForUdf } from "./userDefinedFieldConfigurations";

interface Props {
  objectType: string;
  editedField?: FieldRow;
  form: FieldEditForm;
  readOnly?: boolean;
  onChange: (update: Partial<FieldEditForm>) => void;
  onDelete: (field: FieldRow) => void;
  onFieldSaved: (fieldId: string, updatedObject: ObjectClassDefinition) => void;
  onClose: () => void;
}

const createObjectField = withErrorHandling(adminApi.createObjectField);
const updateObjectField = withErrorHandling(adminApi.updateObjectField);

const FieldEditor = ({ objectType, editedField, form, readOnly, onChange, onDelete, onFieldSaved, onClose }: Props) => {
  const { sendNotificationError } = useNotificationContext();
  const [isLoading, setLoading] = useState(false);

  const createNewField = async (sanitizedForm: FieldEditForm) => {
    const [resp, error] = await createObjectField(objectType, {
      name: sanitizedForm.name.trim(),
      type: sanitizedForm.fieldType,
      configuration: sanitizedForm.fieldConfiguration,
    });

    if (error) {
      sendNotificationError("Failed to create field");
      return undefined;
    }

    return { fieldId: resp.field.id, updatedObject: resp.objectClassDefinition };
  };

  const updateField = async (sanitizedForm: FieldEditForm, fieldId: string) => {
    const [updatedObject, error] = await updateObjectField(objectType, fieldId, {
      name: sanitizedForm.name.trim(),
      configuration: sanitizedForm.fieldConfiguration,
    });

    if (error) {
      sendNotificationError("Failed to update field");
      return undefined;
    }

    return { fieldId, updatedObject };
  };

  const handleSubmit = async () => {
    setLoading(true);
    const isNew = !editedField;
    const sanitizedForm = getSanitizedForm(form, true);
    const result = isNew ? await createNewField(sanitizedForm) : await updateField(sanitizedForm, editedField.id);
    setLoading(false);

    if (result) {
      onFieldSaved(result.fieldId, result.updatedObject);
    }
  };

  const isNewField = !editedField;
  const title = readOnly ? "Field" : isNewField ? "New Field" : "Edit Field";
  const submitButtonCaption = isNewField ? "Add Field" : "Save";
  const isFormValid = isEditFormValid(form);
  const isFormDirty = editedField === undefined || isEditFormDirty(form, editedField);
  const areFieldsDisabled = Boolean(readOnly) || isLoading;
  const isTypeSelectionDisabled = !isNewField || areFieldsDisabled;
  const isSubmitDisabled = Boolean(readOnly) || !isFormValid || !isFormDirty;
  const canDeleteField = !isNewField && !readOnly;

  return (
    <Stack width="26rem" alignItems="flex-start">
      <Stack px={2.5} py={2} direction="row" justifyContent="space-between" alignItems="center" width="100%">
        <Typography variant="h6">{title}</Typography>
        <CloseIconButton onClick={onClose} />
      </Stack>
      <Divider flexItem />
      <Stack p={2.5} spacing={2} width="100%">
        <TextField
          fullWidth
          placeholder="Type field name..."
          disabled={areFieldsDisabled}
          value={form.name}
          onChange={(e) => onChange({ name: e.target.value })}
          error={Boolean(form.validation.name.error)}
          helperText={form.validation.name.error}
          slotProps={{
            input: {
              endAdornment: (
                <Typography color="text.secondary" className="counter-adornment" sx={{ visibility: "hidden" }}>
                  {Math.max(0, maxFieldNameLength - form.name.length)}
                </Typography>
              ),
            },
          }}
          sx={{
            ".MuiInputBase-root": {
              "&.Mui-focused": {
                ".counter-adornment": { visibility: "visible" },
              },
            },
          }}
        />
        <FieldTypeSelect
          fieldType={form.fieldType}
          onChange={(fieldType) => onChange({ fieldType, fieldConfiguration: getDefaultFieldConfigForUdf(fieldType) })}
          disabled={isTypeSelectionDisabled}
        />
        {form.fieldConfiguration && (
          <FieldConfigEditor
            fieldConfiguration={form.fieldConfiguration}
            validation={form.validation.fieldConfiguration}
            isNewField={isNewField}
            readOnly={areFieldsDisabled}
            onChange={(fieldConfiguration) => onChange({ fieldConfiguration })}
          />
        )}
      </Stack>
      <Divider flexItem />
      <Stack px={2} py={1.5} direction="row" alignItems="center" justifyContent="space-between" width="100%">
        {canDeleteField ? (
          <Button color="error" variant="outlined" onClick={() => onDelete(editedField)}>
            Delete
          </Button>
        ) : (
          <HorizontalFill />
        )}
        <Stack direction="row" spacing={1.25} alignItems="center">
          <Button variant="text" color="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={isLoading} variant="contained" onClick={handleSubmit} disabled={isSubmitDisabled}>
            {submitButtonCaption}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default FieldEditor;
