import { Box, Button, DialogActions, DialogTitle, Divider, Drawer } from "@mui/material";
import { useMemo, useState } from "react";
import { withErrorHandling } from "../../../../shared/api/axiosHelper";
import DialogCloseButton from "../../../../shared/components/DialogeCloseButton";
import { useNotificationContext } from "../../../../shared/contexts/NotificationContext";
import { logError } from "../../../../shared/logging";
import { intersect } from "../../../../shared/utilities/arrayHelper";
import adminApi from "../../../api/adminApi";
import { ObjectAccessCategory, ObjectClassDefinition } from "../../../api/types/objectTypes";
import AccessEditor, { AccessValue } from "../../common/AccessEditor";
import {
  getCategoryOptions,
  getInitialAccessValueForBulkEditing,
  mapObjectInternalUserPermissionsToUserAccess,
  mapUserAccessToObjectInternalUserPermissionsRequest,
  UserAccess,
  UserAccessMatrixRow,
} from "./userAccessMatrixModel";

interface Props {
  objectId: string;
  objectDefinition: ObjectClassDefinition;
  categories: ObjectAccessCategory[];
  editedRows: UserAccessMatrixRow[];
  currentAccessItems: UserAccess[];
  onSave: (updatedAccessItems: UserAccess[]) => void;
  onClose: () => void;
}

const updateObjectInternalUserPermissions = withErrorHandling(adminApi.updateObjectInternalUserPermissions);

const EditInternalUserAccessDrawer = ({
  objectId,
  objectDefinition,
  categories,
  editedRows,
  currentAccessItems,
  onSave,
  onClose,
}: Props) => {
  const { sendNotification, sendNotificationError } = useNotificationContext();

  const [isSaving, setSaving] = useState(false);
  const [isDirty, setDirty] = useState(false);
  const [accessValue, setAccessValue] = useState<AccessValue>(() =>
    getInitialAccessValueForBulkEditing(editedRows, { setRole: false })
  );

  const categoryOptions = useMemo(() => getCategoryOptions(categories), [categories]);

  const handleSave = async () => {
    const updatedItems: UserAccess[] = editedRows.map((c) => ({
      id: c.id,
      role: accessValue.selectedRole === "None" ? undefined : accessValue.selectedRole,
      categories: [
        ...accessValue.selectedCategoryIds,
        ...intersect(accessValue.indeterminateCategoryIds ?? [], c.categories),
      ],
    }));

    const otherItems = currentAccessItems.filter((item) => !editedRows.some((row) => row.id === item.id));

    const payload = {
      userPermissions: [...otherItems, ...updatedItems].map(mapUserAccessToObjectInternalUserPermissionsRequest),
    };

    setSaving(true);
    const [updatedUsers, error] = await updateObjectInternalUserPermissions(
      objectDefinition.objectType,
      objectId,
      payload
    );
    setSaving(false);

    if (error) {
      logError(error, "[EditInternalUserAccessDrawer] updateObjectInternalUserPermissions");
      sendNotificationError("Could not update user(s)");
      return;
    }

    sendNotification("User(s) updated successfully");
    onSave(updatedUsers.map(mapObjectInternalUserPermissionsToUserAccess));
    onClose();
  };

  const handleAccessChange = (update: Partial<AccessValue>) => {
    setAccessValue((prev) => ({ ...prev, ...update }));
    setDirty(true);
  };

  return (
    <Drawer anchor="right" open sx={{ "& .MuiDrawer-paper": { width: "25rem" } }}>
      <DialogTitle>
        {editedRows.length === 1 ? `Edit ${editedRows[0]?.name}` : `Bulk Edit ${editedRows.length} users`}
      </DialogTitle>
      <DialogCloseButton onClick={onClose} />
      <Divider />

      <Box display="flex" flexDirection="column" flexGrow={1} py={2.5} px={3} height="100%" overflow="scroll">
        <AccessEditor
          value={accessValue}
          onChange={handleAccessChange}
          roleOptions={[]}
          categoryOptions={categoryOptions}
        />
      </Box>

      <Divider />
      <DialogActions sx={{ py: 2, px: 3, columnGap: 1, justifyContent: "flex-start" }}>
        <Button variant="contained" loading={isSaving} onClick={handleSave} disabled={!isDirty}>
          Apply
        </Button>
        <Button variant="text" color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </DialogActions>
    </Drawer>
  );
};

export default EditInternalUserAccessDrawer;
